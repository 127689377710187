//code below generated from searchBox.ts

function searchBarHandler() {
    var _a, _b, _c;
    const searchBar = document.querySelector(".search-bar");
    if (!searchBar) {
        return;
    }
    //cache the required elements of the search UI
    const searchInput = searchBar.querySelector(":scope .search-header-input");
    const searchFetchURL = (_a = searchBar.dataset) === null || _a === void 0 ? void 0 : _a.contentSuggestions;
    const searchForm = searchBar.querySelector(":scope form.search");
    const searchLinkDestination = searchForm.action;
    const searchResultsWrapper = searchBar.querySelector(":scope .search-results-list");
    const suggestions =
        ((_b = searchResultsWrapper.dataset) === null || _b === void 0 ? void 0 : _b.suggestionsText) || "Suggestions"; //suggestions translation
    const noResults = ((_c = searchResultsWrapper.dataset) === null || _c === void 0 ? void 0 : _c.noResults) || "No results"; //no results translation
    const suggestEl = Object.assign(document.createElement("strong"), { innerText: suggestions });
    const noResultsEl = Object.assign(document.createElement("strong"), { innerText: noResults });
    //prevent the form from submitting - solves Safari submitting on enter
    if (searchForm) {
        searchForm.addEventListener("submit", function (event) {
            event.preventDefault();
        });
    }
    //this function handles click detection outside of the search area
    const listenToClickOutsideSearch = (e) => {
        const target = e.target;
        const outside = !searchBar.contains(target);
        if (outside) {
            searchForm.classList.remove("open");
            document.removeEventListener("click", listenToClickOutsideSearch);
        }
    };
    //handle the open when a user clicks on the magnifying glass
    searchBar.addEventListener("click", () => {
        globals.closePopup.language();
        globals.closePopup.partnercentral();
        searchForm.classList.add("open");
        document.addEventListener("click", listenToClickOutsideSearch);
    });
    function updateSearchResults(data) {
        //remove any previously populated results
        searchResultsWrapper.innerHTML = "";
        // expect data in the form of {suggestions:[{value:string}]}
        if (typeof data === "object" && "suggestions" in data && data.suggestions.length > 0) {
            searchResultsWrapper.appendChild(suggestEl);
            data.suggestions.slice(0, 9).forEach((suggestion) => {
                const listItem = document.createElement("li");
                const link = document.createElement("a");
                link.href = `${searchLinkDestination}#query=${encodeURI(suggestion.value)}`;
                link.textContent = suggestion.value;
                listItem.appendChild(link);
                searchResultsWrapper.appendChild(listItem);
            });
        } else {
            //if there are no results, produce a new element with the text that shows 'No results' translation
            searchResultsWrapper.appendChild(noResultsEl);
        }
    }
    function fetchSuggestions() {
        //protect against missing searchFetchURL or if there is no searchInput
        if (!searchFetchURL || searchInput.value.trim() === "") {
            return;
        }
        const url = `${searchFetchURL}.${encodeURI(searchInput.value)}.json`;
        try {
            fetch(url)
                .then((response) => response.json())
                .then(updateSearchResults)
                .catch((error) => console.error("Error fetching suggestions:", error));
        } catch (error) {
            console.error(`Could not fetch search results: ${error}`);
        }
    }
    const debouncedFetchSuggestions = globals.debounce(fetchSuggestions, 250);
    searchInput.addEventListener("input", debouncedFetchSuggestions);
}
searchBarHandler();

// (function (westcon) {
//     "use strict";
//     westcon.views.searchHeader = Backbone.View.extend({
//         events: {
//             "click ": "magnifyingGlass",
//             "click .search-result-list-item": "selectItemClick",
//             "keyup input": "onKey",
//             "keydown input": "keyDown",
//             "focusin input": "removePlaceHolder",
//         },

//         offsetAllow: 340,

//         initialize: function (e) {
//     this.dropdownTemplate = _.template($("#search-dropdown").html());
//     this.$searchResultsWrapper = this.$(".search-results-wrapper");
//     this.$searchResultsDropdown = this.$(".search-results-container");
//     this.$search = this.$(".search");
//     this.$input = this.$(".search-header-input");
//     this.$resultsLink = this.$("form").attr("action");

//     //have to prevent the form from submitting by default because in Safari, it submits before the code has a change to run
//     document.querySelector("form.search").addEventListener("submit", function (event) {
//         event.preventDefault();
//     });

//     this.contentModel = new westcon.models.searchBoxContent();
//     this.contentModel.path = this.$el.attr("data-content-suggestions");

//     this.listenToMedia(this.closeDropdown.bind(this));
//     this.render();
// },

// fetch: function () {
//     // if (this.productsEnabled) {
//     //     $.when(this.productModel.fetchQuery(), this.contentModel.fetch()).done(this.openDropdown.bind(this));
//     // } else {

//     //the query is created in the form of
//     //   /fr/fr/_jcr_content.suggest.{{query}}.json
//     //where {{query}} is encodeURI(query)

//     $.when(this.contentModel.fetch()).done(this.openDropdown.bind(this));
//     // }
// },

// /*********************** DROPDOWN ***********************/

// renderDropdown: function () {
//     this.$searchResultsWrapper.html(
//         this.dropdownTemplate({
//             productModel: this.productModel,
//             contentModel: this.contentModel,
//             baseUrl: this.getBaseUrl(),
//         })
//     );

//     this.$searchResultsLink = this.$(".search-result-list-link");
// },

// openDropdown: function () {
//     this.renderDropdown();
//     this.setOpenPosition();
// },

// closeDropdown: function () {
//     this.$searchResultsWrapper.html("");
//     this.resetQuery();
// },

// /*********************** NAVIGATION ***********************/

// navigateTo: function () {
//     if (this.getSelected().length) {
//         this.selectOnEnter();
//     } else {
//         this.resultsPage(this.getInput());
//     }
// },

// /**
//  * Navigate to page on click
//  */
// selectItemClick: function (e) {
//     var target = $(e.target);
//     e.preventDefault();

//     this.selectedItemPage(target.text(), target.attr("data-tab"));
// },

// selectOnEnter: function () {
//     this.selectedItemPage(this.getInput(), this.getSelected().attr("data-tab"));
// },

// /**
//  * Navigate to selected list item page
//  */
// selectedItemPage: function (input, selected) {
//     this.resultsPage(input, "&" + "tab=" + selected + "s");
// },

// /**
//  * Got to search results if input is open
//  */
// resultsPage: function (input, selected) {
//     if (this.isSearchOpen()) {
//         globals.navigateTo(this.$resultsLink + "#query=" + input + (selected ? selected : ""));
//         this.closeSearch();
//     }

//     if (this.getCurrentMedia() !== "desktop") {
//         globals.bbGEvent.trigger(globals.events.navigationClose);
//     }
// },

// /*********************** SEARCH INPUT AND SELECTION ***********************/

// onKey: function (e) {
//     if (this.isInputEmpty()) {
//         return this.closeDropdown();
//     }

//     this.keyPress(e);
// },

// keyPress: function (e) {
//     var input;

//     if (e.keyCode === 13) {
//         return this.navigateTo();
//     }

//     if (e.keyCode === 38) {
//         return this.arrowUp();
//     }

//     if (e.keyCode === 40) {
//         return this.arrowDown();
//     }

//     if (this.getInputLength() >= 3) {
//         input = this.getInput();
//         this.setOriginal(input);
//         this.setQuery(input);
//     }
// },

// keyDown: function (e) {
//     if (e.keyCode === 38) {
//         e.preventDefault();
//     }
// },

// arrowUp: function () {
//     var selected = this.getSelected();
//     var idx = this.$searchResultsLink.index(selected) - 1;

//     if (idx >= 0) {
//         this.setSelected(idx);
//     }

//     selected.removeClass("selected");
//     this.addLinkTextToInput();
// },

// arrowDown: function () {
//     var selected = this.getSelected();
//     var idx;

//     if (!selected.length) {
//         this.setFirstAsSelected();
//         this.addLinkTextToInput();
//         return;
//     }

//     idx = this.$searchResultsLink.index(selected) + 1;

//     if (idx + 1 <= this.$searchResultsLink.length) {
//         selected.removeClass("selected");
//         this.setSelected(idx);
//         this.addLinkTextToInput();
//     }
// },

// addLinkTextToInput: function () {
//     var selected = this.getSelected().text();
//     this.setInput(selected ? selected : this.originalText);
// },

// /*********************** TOGGLE SEARCH ***********************/

// openSearch: function () {
//     //in case the language picker or partner central tabs are open
//     globals.closePopup.language();
//     globals.closePopup.partnercentral();

//     this.$search.addClass("open");
//     this.dropdownListener(true, this.closeSearchOnClickOut.bind(this));

//     this.afterSearchToggle();
// },

// closeSearch: function () {
//     this.$search.removeClass("open");
//     this.afterSearchToggle();
// },

// afterSearchToggle: function () {
//     if (!this.isSearchOpen()) {
//         this.closeDropdown();
//     }

//     if (!this.isSearchOpen()) {
//         this.clearInput();
//     }
// },

// closeSearchOnClickOut: function (e) {
//     var target = $(e.target);

//     if (target.hasClass("search-bar")) {
//         return;
//     }

//     if (
//         !target.hasClass("search") &&
//         this.$el[0] !== target.closest(".search")[0] &&
//         target.closest(".search").length === 0
//     ) {
//         this.closeSearch();
//         this.closeDropdown();
//         this.dropdownListener(false);
//     }
// },

// setOpenPosition: function () {
//     if (window.innerHeight < this.offsetAllow) {
//         return;
//     }

//     var bottomOffset = window.innerHeight - (this.$el.offset().top - $(window).scrollTop());
//     this.$(".search-results-container").toggleClass("open-up", bottomOffset < this.offsetAllow);
// },

// /**
//  * Handles magnifying glass click
//  */
// magnifyingGlass: function (e) {
//     if (!this.isSearchOpen()) {
//         this.openSearch(e);
//         return;
//     }
//     //only close the menu if the user clicks on the magnifying glass again
//     if ($(e.target).hasClass("search-bar")) {
//         // if (this.isSearchOpen() && this.isInputEmpty()) {
//         this.closeSearch(e);
//         this.dropdownListener(false);
//         return;
//     }
// },

// /*********************** GETTERS SETTERS IS ***********************/

// isSearchOpen: function () {
//     return this.$search.hasClass("open");
// },

// isInputEmpty: function () {
//     return this.$input.val().length === 0 && !this.$input.is(":focus");
// },

// isDropdownOpen: function () {
//     return this.$searchResultsDropdown.hasClass("show");
// },

// clearInput: function () {
//     this.$input.val("");
// },

// getInput: function () {
//     return this.$input.val();
// },

// getInputLength: function () {
//     return this.getInput().length;
// },

// /**
//  * Get base url from searh page url
//  */
// getBaseUrl: function () {
//     var resultArr = this.$resultsLink.split("/");
//     resultArr.pop();
//     return resultArr.join("/");
// },

// /**
//  * Get selected dropdown item
//  */
// getSelected: function () {
//     return this.$(".search-result-list-link.selected");
// },

// setInput: function (val) {
//     this.$input.val(val);
// },

// /**
//  * Set original query
//  */
// setOriginal: function (text) {
//     this.originalText = text;
// },

// /**
//  * Set query to model
//  */
// setQuery: function (query) {
//     this.contentModel.setQuery(query);
//     this.fetch();
// },

// /**
//  * Reset query on model
//  */
// resetQuery: function () {
//     this.contentModel.resetQuery();
// },

// /**
//  * Set selected link item
//  * @param {number} idx
//  */
// setSelected: function (idx) {
//     this.$searchResultsLink.eq(idx).addClass("selected");
// },

// /**
//  * Select first item
//  */
// setFirstAsSelected: function () {
//     return $(_.first(this.$searchResultsLink)).addClass("selected");
//         },
//     });
// })(westcon);
