//––––––––––––––––––––––––––––––––––––––––
// Specific vendor behaviour in mega menu
//––––––––––––––––––––––––––––––––––––––––

const vendorList = document.querySelectorAll(".vendors-list-of-vendors a");
const vendorFilters = document.querySelector(".vendors-filters");

if (vendorList && vendorFilters) {
    const vl = document.createElement("div");
    vl.ariaHidden = true;
    const alp = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
    ];
    const allAlp = alp.map((a) => {
        return { l: a, c: 0 };
    });
    //systematically counts how many vendors start with each alphabet character
    vendorList.forEach((v) => {
        if (v.title) {
            const a = v.title.slice(0, 1).toUpperCase();
            v.id = `v${a}`;
            if (alp.includes(a)) {
                allAlp[alp.indexOf(a)].c++;
            }
        }
    });
    // expect [{l:'A',c:2},{l:'B',c:0},...]

    allAlp.forEach((a) => {
        //create a new button to be able to skip to each alphabet letter
        const li = Object.assign(document.createElement("button"), {
            innerText: a.l,
            inert: a.c ? false : true,
            tabIndex: -1,
        });
        //if the c key is non zero, it has vendors so apply the target and add an event listener to focus on the first matching vendor
        if (a.c) {
            li.dataset.target = `#v${a.l}`;
            li.addEventListener("click", (e) => {
                e.preventDefault();
                const t = document.querySelector(e.target.dataset.target);
                if (t) {
                    t.focus();
                }
            });
        }
        vl.appendChild(li);
    });

    vendorFilters.appendChild(vl);
}
