(function (westcon) {
    'use strict';

    westcon.views.partnerCentralLogout = Backbone.View.extend({

        events: {
            click: 'logout'
        },

        /**
         * Handle SSO logout
         */
        logout: function (e) {
            e.preventDefault();
            Cookies.set('partnerCentralLogoutUrl', e.target.href);
            location.href = '/system/sling/logout.html?tstamp=' + new Date().getTime();
        }

    });

})(westcon);
