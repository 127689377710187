// data-video-info only takes the video id, the width and height
// data-video-vars take all other variables.
// data-video-vars params can be found here: https://developers.google.com/youtube/player_parameters?playerVersion=HTML5

(function (westcon) {
    "use strict";

    westcon.views.video = Backbone.View.extend({
        ytMediaSizes: {
            tablet: { width: 640, height: 360 },
            mobile: { width: 300, height: 170 },
        },

        events: {
            click: "playVideo",
        },

        initialize: function () {
            this.waiting = false;
            var player;
            this.player = player;
            this.ytIframe = document.createElement("div");
            this.ytIframe.id = "ytplayer";

            const closeFunction = () => {
                if (this.waiting) {
                    return; //take no action in case the video is still waiting to load
                }
                this.player.stopVideo();
                this.dialog.close();
            };

            //create the dialog element and pass the closeFunction as the action to take when the dialog is closed
            this.dialog = globals.createNewModal(this.ytIframe, closeFunction);

            //extract the video variables into objects

            this.videoInfo = Object.fromEntries(this.el.dataset.videoInfo.split(" ").map((x) => x.split(":")));
            // expect ${model.fullscreenDisabled && 'fs:0'} ${model.relatedEnabled ? 'rel:1' : 'rel:0' } hl:${model.language}
            this.videoVars = Object.fromEntries(this.el.dataset.videoVars.split(" ").map((x) => x.split(":")));
            this.videoVars.hl = globals.langCodeFull;

            window.loader.YouTubeAPI.load();
            // .done(function () {
            // _this.$options =_this.videoInfo;
            // _this.$options.playerVars = _this.getOptions(_this.videoVars);
            // _this.listenToMedia(_this.renderForMedia.bind(_this));
            // });
        },

        //–––––––––––––––––––––––––––––––––––
        createPlayer: function () {
            const options = Object.assign(this.videoInfo, {
                events: {
                    onReady: () => {
                        this.player.playVideo();
                        this.waiting = false;
                    },
                },
            });
            this.player = new YT.Player("ytplayer", options);
        },

        //–––––––––––––––––––––––––––––––––––
        playVideo: function () {
            //avoid allowing users to click multiple times
            if (!this.waiting) {
                this.dialog.showModal();
                if (typeof this.player === "undefined") {
                    var _this = this;
                    this.waiting = true;
                    window.loader.YouTubeAPI.load().done(function () {
                        _this.createPlayer();
                    });
                } else {
                    this.player.playVideo();
                }
            }
        },
    });
})(westcon);
