(function (westcon, bb) {
    "use strict";

    westcon.views.pardotForm = bb.View.extend({
        initialize: function () {
            window.addEventListener(
                "message",
                function (e) {
                    if (typeof e.data !== "string" || e.data.indexOf("|") === -1) {
                        return;
                    }
                    const data = e.data.split("|");

                    if (data[0] === "pardotSetHeight" && data.length > 2) {
                        const formHash = data[1];
                        const height = parseInt(data[2]);
                        if (height) {
                            const pardotForm = document.getElementById(`pardot-${formHash}`);
                            if (pardotForm) {
                                pardotForm.style.height = `${height}px`;
                            }
                        }
                    }
                },
                false
            );
        },
    });
})(westcon, Backbone);
