/**
 * Example usage for globals
 * this global util returning currency number with comma
 * example: 1200 = 1,200
 */
globals.formatCurrency = function (number) {
    "use strict";
    return number.toLocaleString();
};

/**
 * Takes a param encoded by jQuery.param()
 * and parses into an object.
 */
globals.deparam = function (param) {
    "use strict";

    var paramHash = decodeURIComponent(param).replace(/\+/g, " ").split("&");
    var obj = {};
    var hashArr;
    _.each(paramHash, function (hash) {
        hashArr = hash.split("=");
        obj[_.first(hashArr)] = _.last(hashArr);
    });

    return obj;
};

/**
 * Get the current media by resolution
 * @returns {*}
 */
globals.getCurrentMedia = function () {
    "use strict";

    /**
     * Find between media screen resolutions
     * @param {number} x - current resolution number
     * @param {Array} media - array win min and max resolution numbers
     * @returns {boolean}
     */
    function between(x, media) {
        return x >= media[0] && x <= media[1];
    }

    /**
     *
     */
    for (var device in globals.media.devices) {
        if (between(window.innerWidth, globals.media.devices[device])) {
            return device;
        }
    }

    return globals.media.default;
};

// (function () {
//     "use strict";

//     var isTouch =
//         "createTouch" in document || "ontouchstart" in document.documentElement;

//     if (!isTouch) {
//         $("body").addClass("notouch");
//     }
// })();

/**
 * Images Loaded
 * @param {Function} callback
 * @param {Oject} fireOne
 */
$.fn.imagesLoaded = function (callback, fireOne) {
    "use strict";

    var elems = this.filter("img");
    var elemsLen = elems.length - 1;

    elems
        .on("load", function (e) {
            if (fireOne) {
                elemsLen--;
                if (!elemsLen) {
                    callback.call(elems, e);
                }
            } else {
                callback.call(this, e);
            }
        })
        .each(function () {
            // cached images don't fire load sometimes, so we reset src.
            if (this.complete || this.complete === undefined) {
                const r = this.src;
                this.src = "";
                this.src = r;
            }
        });
};
