(function (westcon) {
    "use strict";

    westcon.views.login = Backbone.View.extend({
        events: {
            "keyup .login-field": "validateFields",
            "click .create-user": "hideSignIn",
            "click .log-in": "hideSignUp",
            "click #signupbutton": "submitSignUp",
        },

        /**
         * Initialize
         */
        initialize: function () {
            this.$("#j_username").focus();
        },

        /**
         * Initialize
         */
        validateFields: function () {
            var passwordsMatch = this.$("#password").val() === this.$("#confirmpassword").val();

            if (passwordsMatch) {
                this.$("#mismatchpass").addClass("hide");
                this.$("#password").removeClass("error");
                this.$("#confirmpassword").removeClass("error");
            } else {
                this.$("#mismatchpass").removeClass("hide");
                this.$("#password").addClass("error");
                this.$("#confirmpassword").addClass("error");
            }

            var isValid = this.$("#signupform")[0].checkValidity() && passwordsMatch;
            this.$("#signupbutton").prop("disabled", !isValid);
        },

        /**
         * Hides the sign-up part of the form
         */
        hideSignUp: function () {
            this.$("#signup-container").addClass("hide");
            this.$("#signin-container").removeClass("hide");
            this.$("#j_username").focus();
            this.clearSignUp();
        },

        /**
         * Hides the sign-in part of the form
         */
        hideSignIn: function () {
            this.$("#signup-container").removeClass("hide");
            this.$("#signin-container").addClass("hide");
            this.$("#username").focus();
        },

        /**
         * Clears any errors
         */
        clearSignUp: function () {
            this.$("#usertaken").addClass("hide");
            this.$("#miscerror").addClass("hide");
            this.$("#mismatchpass").addClass("hide");
            this.$("#password").removeClass("error");
            this.$("#confirmpassword").removeClass("error");
            this.$("#signupform")[0].reset();
        },

        /**
         * Submits the sign-up form
         */
        submitSignUp: function (event) {
            event.preventDefault();

            var _this = this;

            var data = {};
            data.username = this.$("#username").val();
            data.email = this.$("#email").val();
            data.password = this.$("#password").val();
            data.companyname = this.$("#companyname").val();
            data.country = this.$("#country").val();
            data.state = this.$("#state").val();
            data.wcmnumber = this.$("#wcmnumber").val();
            data.securePath = this.$("#securePath").val();

            $.post(_this.$("#actionPath").val(), data)
                .done(function () {
                    window.location.href = _this.$("#redirectPath").val();
                })
                .fail(function (xhr) {
                    if (xhr.status === 406) {
                        _this.$("#usertaken").removeClass("hide");
                    } else if (xhr.status === 501) {
                        _this.$("#inauthorerr").removeClass("hide");
                    } else {
                        _this.$("#miscerror").removeClass("hide");
                    }
                });
        },
    });
})(westcon);
