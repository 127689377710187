//––––––––––––––––––––––––––––––––––––––––
//  Vendor showcase
//––––––––––––––––––––––––––––––––––––––––

document.querySelectorAll("body:not([data-edit-mode]) .vendor-showcase__list:not(.active)").forEach((showcase, indexShowcase) => {
    showcase.classList.add("active");
    globals.isVisibleObserver.observe(showcase);
    const containerID = "vdr-show-" + indexShowcase;
    showcase.dataset.id = containerID;

    const vendors = Array.from(showcase.querySelectorAll("li"));

    vendors.forEach((vendor, indexVendor) => {
        vendor.dataset.id = indexVendor.toString();
    });

    const shiftVendors = () => {
        const { visible } = showcase.dataset;

        // only apply if the tab and the component are both visible and menu not open
        if (visible === "1" && document.visibilityState === "visible" && !document.body.classList.contains("menu-open")) {
            let first = vendors.length - 1,
                last = 0;

            //which vendors are visible
            vendors.forEach((vendor) => {
                const id = vendor.dataset.id ? parseInt(vendor.dataset.id, 10) : 0;

                const rect = vendor.getBoundingClientRect();
                const isInViewport =
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth);
                if (isInViewport) {
                    if (id < first) {
                        first = id;
                    }
                    if (id > last) {
                        last = id;
                    }
                }
            });
            // increment by equal amount, if possible
            const target =
                last === vendors.length - 1 ? 0 : 2 * last - first > vendors.length - 1 ? vendors.length - 1 : 2 * last - first;

            const targetSlide = document.querySelector(`[data-id="${containerID}"] [data-id="${target}"]`);

            if (targetSlide) {
                targetSlide.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "nearest",
                });
            }
        }
    };
    let autoScroll,
        permanent = false;

    function startTimer() {
        if (!autoScroll && !permanent) {
            autoScroll = setInterval(shiftVendors, 5000);
        }
    }
    function stopTimer(perm = false) {
        if (perm) {
            return;
        }
        clearInterval(autoScroll);
        autoScroll = null;
        if (perm) {
            permanent = true;
        }
    }
    //remove the event listener on a click
    //!in future, update to swipe listener
    const pssve = globals.passiveSupported;
    showcase.addEventListener("click", () => stopTimer(true), pssve);
    showcase.addEventListener("mouseenter", () => stopTimer(), pssve);
    showcase.addEventListener("mouseleave", () => startTimer(), pssve);
    window.addEventListener("focus", () => startTimer(), pssve);
    window.addEventListener("blur", () => stopTimer(), pssve);

    startTimer();
});
