"use strict";
/**
 * Globals namespace
 * @type {globals|*|{}}
 */
var globals = globals || {};
var westcon = westcon || {};
globals.bbGEvent = globals.bbGEvent || _.extend({}, Backbone.Events);

/**
 * Backbone globals
 */
westcon.views = westcon.views || {};
westcon.models = westcon.models || {};
westcon.collections = westcon.collections || {};
westcon.routers = westcon.routers || {};
westcon.something = westcon.something || {};

/**
 * Define the devices resolution supported
 */
globals.media = {
    default: "desktop",
    devices: {
        mobile: [0, 767],
        tablet: [768, 1124],
        desktop: [1125, 10000],
    },
};

function findWhichCountry() {
    //library of exact matches for countries; needs updating with new country launches
    const validCountries = [
        "global",
        "mat",
        "ssa",
        "at",
        "be",
        "cz",
        "dk",
        "fi",
        "fr",
        "de",
        "gr",
        "it",
        "nl",
        "no",
        "pl",
        "pt",
        "es",
        "se",
        "ch",
        "uk",
        "me",
        "au",
        "hk",
        "id",
        "kr",
        "my",
        "nz",
        "ph",
        "sg",
        "tw",
        "th",
        "vn",
    ];
    const path = window.location.pathname;
    //on public site, path is of structure /global/en.html or /uk/en.html etc.
    //extract the words between slash and remove any empty entries
    const pathParts = path.toLowerCase().split("/");

    const country = pathParts.filter((p) => validCountries.includes(p));
    //check for each pathPaths
    return country.length ? country[0] : "";
}
function findWhichLanguage() {
    const validLangs = ["en", "fr", "de", "nl", "id", "th", "cht", "vn", "kr", "es", "pl", "cz", "pt", "it"];
    const path = window.location.pathname;
    const pathParts = path.toLowerCase().split("/");
    const lang = pathParts.filter((p) => validLangs.includes(p));
    return lang.length ? lang[0] : "en";
}
function getLangFromHTML() {
    if (document.documentElement && document.documentElement.lang) {
        return document.documentElement.lang;
    }
    return findWhichLanguage();
}

globals.country = findWhichCountry();
globals.langCode = findWhichLanguage();
globals.langCodeFull = getLangFromHTML();

/**
 *
 * @type {{navigation: string}}
 */
globals.events = {
    navigationClose: "click:closeMegaMenu",
    openModal: "openModal",
};

globals.api = {
    root: "/yrest",
    product: "/yrest/products",
    content: "/_jcr_content.json",
    search: "/yrest/products/search",
    productSuggest: "/yrest/products/suggestions",
    contentSuggest: "/content/wcgcom/demo/example/home/_jcr_content.suggest",
};

/**
 * Open modal
 */
//!need to update this
globals.openModal = function (content, opt) {
    "use strict";
    content = content || "";
    globals.bbGEvent.trigger(globals.events.openModal, content, opt);
};

/**
 * Animated scroll to top of the page
 */
globals.scrollToTop = function (scrollAmt = 0) {
    window.scrollTo({ top: scrollAmt, behavior: "smooth" });
    // $("html, body").animate({ scrollTop: scrollAmt }, "slow");
};

/**
 * Navigate to url
 * @param {string} url
 */
globals.navigateTo = function (url) {
    window.location = url;
};

/**
 * Get image
 * @param {string} type
 * @param {Array} imageArray
 * @param {string} pattern
 * @param {string} replace
 *
 * @returns {string} a url
 *
 * Type is the image type, 'thumbnail' and 'product'.
 * ImageArray is an array of image objects to pick from.
 * Pattern is the pattern in the image object url that needs to be replaced
 * Replace is the string that will replace the pattern in the image object url.
 *
 * If there is no image array a default image url will be returned
 */
globals.getImage = function (type, imageArray, pattern, replace) {
    console.log({ type, imageArray, pattern, replace });
    if (imageArray) {
        return _.findWhere(imageArray, { format: type }).url.replace(pattern, replace);
    } else {
        return "/etc/designs/westcon-wcgcom/clientlib-site/assets/images/default-product.png";
    }
};

//––––––––––––––––––––––––––––––––––––––––
//  Is passive supported?
//––––––––––––––––––––––––––––––––––––––––
//a variable that checks if browser supports passive event listeners
//which can be leveraged in performance optimised event listeners throughout code
// add the following into the event listener options: globals.passiveSupported
globals.passiveSupported = false;

try {
    const options = {
        get passive() {
            globals.passiveSupported = { passive: true };
            return false;
        },
    };

    window.addEventListener("test", null, options);
    window.removeEventListener("test", null, options);
} catch (err) {
    globals.passiveSupported = false;
}

//––––––––––––––––––––––––––––––––––––––––
//  Create and return a new modal
//––––––––––––––––––––––––––––––––––––––––
/**
 * Get image
 * @param {object} content
 * @param {Function} closeFunction
 *
 * @returns {HTMLDialogElement} The dialog function
 *
 */
globals.createNewModal = (content, closeFunction) => {
    const dialog = document.createElement("dialog");
    const closeBtn = document.createElement("div");
    closeBtn.classList.add("close-dialog");

    //!should implement check to see if it's a single object or array, or nodelist, etc
    dialog.append(closeBtn, content);
    document.body.appendChild(dialog);
    //apply the close function to both close button and clicking outside the modal
    closeBtn.addEventListener("click", closeFunction, globals.passiveSupported ? { passive: true } : false);
    dialog.addEventListener(
        "click",
        (event) => {
            if (event.target.nodeName === "DIALOG") {
                closeFunction();
            }
        },
        globals.passiveSupported ? { passive: true } : false
    );
    //returns the dialog object back to the original function
    return dialog;
};

/**
 * On YouTube Iframe Ready
 */
// globals.playYouTubeVideo = function (options) {
//     var opts = _.extend(
//         {
//             events: {
//                 onReady: function () {
//                     player.playVideo();
//                 },
//             },
//         },
//         options
//     );
//     var player = new YT.Player("ytplayer", opts);
// };

//––––––––––––––––––––––––––––––––––––––––
//  General element is visible observer
//––––––––––––––––––––––––––––––––––––––––
globals.isVisibleObserver = new IntersectionObserver(
    (entries) => {
        entries.forEach((entry) => {
            if (entry.target instanceof HTMLElement) {
                if (entry.isIntersecting) {
                    entry.target.dataset.visible = "1";
                } else {
                    entry.target.dataset.visible = "0";
                }
            }
        });
    },
    {
        threshold: 0.5,
    }
);

//––––––––––––––––––––––––––––––––––––––––
//  General throttle function for JS
//––––––––––––––––––––––––––––––––––––––––
globals.throttleTimer = false;
globals.throttle = (callback, time) => {
    if (globals.throttleTimer) {
        return;
    }
    globals.throttleTimer = true;
    setTimeout(() => {
        callback();
        globals.throttleTimer = false;
    }, time);
};

globals.debounce = (func, wait) => {
    let timeout;
    return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
};

//––––––––––––––––––––––––––––––––––––––––
//  Observer used for the intro animations
//––––––––––––––––––––––––––––––––––––––––
globals.introObserver = new IntersectionObserver(
    (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add("intro");
                //possible code to use to remove the animation-name from the element at the end of the animation for better optimization
                //however, it's not producing the desired effect in Safari
                // entry.target.addEventListener("animationend", () => {
                //     entry.target.dataset.ae = true;
                // });
                globals.introObserver.unobserve(entry.target);
            }
        });
    },
    {
        threshold: 0,
        rootMargin: "-50px 150px",
    }
);
//––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
//  Observer used for to check when lazy loaded images are finished
//––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
globals.imageVerifyLoad = new IntersectionObserver(
    (images) => {
        images.forEach((image) => {
            if (image.isIntersecting) {
                const img = image.target;
                const isLoaded = img.complete || img.naturalHeight !== 0; //firefox returns natural height of 0
                img.dataset.ready = isLoaded;
                if (!isLoaded) {
                    img.onload = () => {
                        img.dataset.ready = img.complete;
                    };
                    img.onerror = () => {
                        img.dataset.error = true;
                    };
                }
                globals.introObserver.unobserve(image.target);
            }
        });
    },
    {
        threshold: 0,
        rootMargin: "-50px 150px",
    }
);

//–––––––––––––––––––––––––––––––––––––––––––––––––––––––
//  opening popups in the upper nav should close any open
//  popups, therefore call the globals.closePopup.search etc
//–––––––––––––––––––––––––––––––––––––––––––––––––––––––
globals.closePopup = {
    search: () => {
        document.querySelector(".search").classList.remove("open");
    },
    partnercentral: () => {
        //partnercentral may not be present on all sites
        const pc = document.querySelector(".partner-central-applications");
        if (pc instanceof HTMLElement) {
            pc.classList.remove("hover");
        }
    },
    language: () => {
        document.querySelector(".language-picker").classList.remove("open");
    },
    closeAll: function () {
        this.search();
        this.partnercentral();
        this.language();
    },
};
