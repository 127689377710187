//only load animations if user is not in the CMS and this function hasn't already executed
if (document.querySelector(".editmode") === null && !document.body.classList.contains("animations")) {
    //––––––––––––––––––––––––––––––––––––––––
    //  Listener for mouse move
    //––––––––––––––––––––––––––––––––––––––––
    const pos = { x: 0, y: 0 };

    const saveCursorPosition = function (x, y) {
        pos.x = (x / window.innerWidth).toFixed(2);
        pos.y = (y / window.innerHeight).toFixed(2);
        document.documentElement.style.setProperty("--mouse-x", pos.x);
        document.documentElement.style.setProperty("--mouse-y", pos.y);
    };

    let rafId;
    const updateCursorPosition = function (e) {
        saveCursorPosition(e.clientX, e.clientY);
        rafId = null;
    };

    //use requestIdleCallback to start the mousemove event listener
    //partly because it's not supported in Safari, which is having problems with the time
    //to recalculate styles
    if ("requestIdleCallback" in window) {
        // Schedule initialization to run during the next idle period.
        requestIdleCallback(() => {
            document.addEventListener(
                "mousemove",
                (e) => {
                    if (!rafId) {
                        rafId = window.requestAnimationFrame(() => {
                            updateCursorPosition(e);
                        });
                    }
                },
                globals.passiveSupported
            );
        });
    } else {
        //if not supported, just assign these css variables to the document
        // document.documentElement.style.setProperty("--mouse-x", 0.5);
        // document.documentElement.style.setProperty("--mouse-y", 0.25);
    }

    document
        .querySelectorAll(
            "[data-intro]"
            // ":is(" +
            //     [
            //         ".tile-component",
            //         ".oneColumnContent",
            //         //".textimagewithcta",
            //         ".text-image-with-cta",
            //         // ".newsShowcase",
            //         ".news-showcase ",
            //         // ".carousel",
            //         ".carousel-slides",
            //         // ".testimonial",
            //         ".testimonial-wrapper",
            //         ".gradient-border",
            //         ".blog-recent-entries__card",
            //         // ".featureItem",
            //         ".feature-item",
            //         // ".twoColumnsContent",
            //         ".two-columns-content",
            //         ".intro__quick-links",
            //         ".accordion-dl",
            //         ".callToAction",
            //         ".careerProfileItem",
            //         ".ctaImageBox",
            //     ].join() +
            //     ")"
        )
        .forEach((i) => {
            if (i) {
                globals.introObserver.observe(i);
            }
        });

    //animations only enabled if this class applied to body, as protection in case the javascript wasn’t run
    document.body.classList.add("animations");
}
