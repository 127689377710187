/**
 * Prevent zombies by applying a close method.
 * Use it to wrap any high level view using the router object.
 *
 * Example:
 *      this.showView(
 *          YOUR VIEW CODE HERE
 *      );
 *
 * @param {Object} view
 */
Backbone.Router.prototype.showView = function (view) {
    'use strict';

    /**
     * Check for existing view and execute close method
     */
    if (this.currentView) {
        this.currentView.close();
    }

    /**
     * Append the current view to the currentView,
     * so we can use it for the next iteration
     */
    this.currentView = view;

    /**
     * Render the current view so it will be displayed,
     * otherwise the view will not appear on the page
     */
    this.currentView.render();

    return this;
};
