/**
 * @author Salvatore Randazzo
 */

(function ($, westcon) {
    "use strict";

    /**
     * Loader
     * @returns {{init: Function}}
     * @constructor
     */
    var Loader = function () {
        var components = null;
        var view = "";
        var model = "";
        var collection = "";
        var viewsInstances = [];

        /**
         * Initializer
         */
        var init = function () {
            components = $(".component:not(.component-modal)");
            _.each(components, function (component) {
                view = $(component).attr("data-view");
                model = $(component).attr("data-model");
                collection = $(component).attr("data-collection");

                var duplicatedInstance = viewsInstances.some(function (el) {
                    return el.el === component;
                });

                if (!duplicatedInstance) {
                    if (westcon.views[view] !== undefined) {
                        if (collection === undefined) {
                            viewsInstances.push(
                                new westcon.views[view]({
                                    el: $(component),
                                    model: westcon.models[model] !== undefined ? new westcon.models[model]() : null,
                                })
                            );
                        } else {
                            viewsInstances.push(
                                new westcon.views[view]({
                                    el: $(component),
                                    collection:
                                        westcon.collections[collection] !== undefined
                                            ? new westcon.collections[collection]()
                                            : null,
                                })
                            );
                        }
                    } else {
                        throw new Error("No view found for " + view);
                    }
                }
            });
        };

        /**
         * A Promise to load the YouTube IFrame API using the load method.
         */
        var youTubeAPILoaderPromise = _.extend($.Deferred(), {
            triggered: false,

            /**
             * Function to call to load the YouTube API only once. It will return a promise, which is resolved when
             * the API is available. Usage example:
             * load().done(function() {...});
             *
             * @returns {Deferred} YouTubeAPI Loader Promise
             */
            load: function load() {
                if (!this.triggered) {
                    this.triggered = true;

                    //load youtube video API
                    var tag = document.createElement("script");
                    tag.defer = true;
                    tag.src = "https://www.youtube.com/iframe_api";
                    var firstScriptTag = document.getElementsByTagName("script")[0];
                    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
                }

                return this;
            },
        });

        /**
         *
         */
        // var WuFooAPILoaderPromise = _.extend($.Deferred(), {
        //     triggered: false,

        //     /**
        //      * Function to call to load the Wufoo API only once. It will return a promise, which is resolved when
        //      * the API is available. Usage example:
        //      * load().done(function() {...});
        //      *
        //      * @returns {Deferred} WuFooAPI Loader Promise
        //      */
        //     load: function load() {
        //         if (!this.triggered) {
        //             this.triggered = true;

        //             //load wufoo form API
        //             var tag = document.createElement("script");
        //             tag.src = "https://www.wufoo.com/scripts/embed/form.js";
        //             var firstScriptTag = document.getElementsByTagName("script")[0];

        //             /**
        //              * onload
        //              */
        //             tag.onload = function () {
        //                 window.loader.WuFooAPI.resolve();
        //             };

        //             firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        //         }

        //         return this;
        //     },
        // });

        // Public
        return {
            init: init,
            YouTubeAPI: youTubeAPILoaderPromise,
            // WuFooAPI: WuFooAPILoaderPromise,
            viewInstances: viewsInstances,
        };
    };

    /**
     * Wistia Api loader, which loads the Wistia JS Api, only of a wistia component is present on the page.
     */
    var loadWistiaAPI = function () {
        if (document.querySelector("[data-wistia-video]")) {
            const script = Object.assign(document.createElement("script"), {
                src: "https://fast.wistia.net/assets/external/E-v1.js",
                async: true,
            });
            document.head.append(script);
        }
        // if ($(".component-wistiaVideo").length > 0) {
        //     var tag = document.createElement("script");
        //     tag.src = "https://fast.wistia.net/assets/external/E-v1.js";
        //     // tag.aysnc = "true"; //don't need both async and defer
        //     tag.defer = "defer";
        //     var firstScriptTag = document.getElementsByTagName("script")[0];
        //     firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        // }
    };

    // Starting the application
    window.loader = new Loader();
    window.loader.init();

    loadWistiaAPI();

    if (typeof ContextHub !== "undefined" && ContextHub && ContextHub.eventing) {
        /*
         * Register listener for when teasers (activities) are loaded.
         * This code hooks into how teasers are loaded outside the editor.html context,
         * for detecting the authoring changes, see author_overrides.js
         */
        ContextHub.eventing.on(ContextHub.Constants.EVENT_TEASER_LOADED, function () {
            window.loader.init();
        });
    }

    /**
     * Triggered when the YouTube iframe API is loaded
     */
    window.onYouTubeIframeAPIReady = function () {
        window.loader.YouTubeAPI.resolve();
    };
})(jQuery, westcon);
