class LanguageSelector {
    constructor() {
        this.el = document.getElementById("language_selector_v2");
        if (!this.el || !this.el.dataset.countries) {
            return;
        }
        this.dom = {
            countryInput: this.el.querySelector('select[name="countries"]'),
            languagesInput: this.el.querySelector('select[name="languages"]'),
            languageSelectorBTN: this.el.querySelector("button"),
        };

        if (!this.dom.countryInput || !this.dom.languagesInput || !this.dom.languageSelectorBTN) {
            return;
        }

        //set up defaults
        const countryJSON = JSON.parse(this.el.dataset.countries);
        this.dom.countries = countryJSON.countries ? countryJSON.countries : [];
        //add listeners
        this.dom.countryInput.addEventListener("change", this.countryInputChange.bind(this));
        this.dom.languageSelectorBTN.addEventListener("click", this.selectLanguage.bind(this));

        this.populateCountrySelect();
        this.populateLanguageSelect();
        this.dom.selectedUrl = this.dom.languagesInput.value;
    }

    populateCountrySelect() {
        const countriesElements = this.dom.countries
            .map((country, index) => {
                return `<option value="${index}">${country.name}</option>`;
            })
            .join("");

        this.dom.countryInput.innerHTML = countriesElements;
    }

    countryInputChange(event) {
        this.populateLanguageSelect(parseInt(event.target.value));
    }

    populateLanguageSelect(countryIndex = 0) {
        if (!this.dom.countries.length) {
            //the countries haven't pulled through, so cancel, and hide the components except for button for all countries as it will not work
            this.dom.countryInput.style.display = "none";
            this.dom.languagesInput.parentElement.style.display = "none";
            this.dom.languageSelectorBTN.style.display = "none";
            return;
        }
        const languages = this.dom.countries[countryIndex].languages;
        const languageElements = languages
            .map((language) => {
                return `<option value="${language.url}">${language.name}</option>`;
            })
            .join("");

        this.dom.languagesInput.innerHTML = languageElements;
    }

    selectLanguage() {
        const url = this.dom.languagesInput.value;
        const win = window.open(url, "_blank");

        if (win) {
            win.focus();
        } else {
            console.error("Please allow popups for this website");
        }
    }
}
//only process once the dom is ready, to ensure the language component is found
document.addEventListener("DOMContentLoaded", function () {
    if (document.getElementById("language_selector_v2")) {
        new LanguageSelector();
    }
});

// function LanguageSelector() {
//     this.$el = $('#language_selector_v2');

//     if (this.$el.length) {
//         this.setupDefaults();
//         this.addListeners();
//         this.populateCountrySelect();
//         this.populateLanguageSelect(1);
//         this.dom.selecteUrl = $(this.dom.$languagesInput).val();
//     }
// }

// LanguageSelector.prototype.setupDefaults = function () {
//     this.dom = {
//         $countryInput: this.$el.find("select[name='countries']"),
//         $languagesInput: this.$el.find("select[name='languages']"),
//         $languageSelectorBTN: this.$el.find(".languageSelectorBTN"),
//         countries: JSON.parse(this.$el[0].attributes["data-countries"].value).countries,
//     }
// };

// LanguageSelector.prototype.addListeners = function () {
//     this.dom.$countryInput.on('change', this.countryInputChange.bind(this));
//     this.dom.$languageSelectorBTN.on('click', this.selectLanguage);
// };

// LanguageSelector.prototype.populateCountrySelect = function () {
//     var countriesElements = "";
//     for (var i = 0; i < this.dom.countries.length; i++){
//         countriesElements += "<option value='" + i + "'>" + this.dom.countries[i].name + "</option>";
//     }
//     $(this.dom.$countryInput).html(countriesElements);
// };

// LanguageSelector.prototype.countryInputChange = function (element) {
//     this.populateLanguageSelect($(element.target).val());
// }

// LanguageSelector.prototype.populateLanguageSelect = function (countryIndex) {
//     var languagesElements = "";
//     var languages = this.dom.countries[countryIndex].languages;
//     for (var i = 0; i < languages.length; i++) {
//         languagesElements += "<option value='" + languages[i].url + "'>" + languages[i].name + "</option>";
//     }
//     $(this.dom.$languagesInput).html(languagesElements);
// };

// LanguageSelector.prototype.selectLanguage = function () {
//     var url = $($("#language_selector_v2 select[name='languages'] option:selected")[0]).val();
//     var win = window.open(url, '_blank');
//     if (win) {
//         win.focus();
//     } else {
//         console.log('Please allow popups for this website');
//     }
// }

// $(document).ready(function () {
//      new LanguageSelector();
// });
