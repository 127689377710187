/**
 * Custom remove method to remove view from the DOM
 * @returns {Backbone.View}
 */
Backbone.View.prototype.remove = function () {
    'use strict';

    this.undelegateEvents();
    this.$el.empty();
    this.stopListening();
    return this;
};

/**
 * Close view to prevent zombies on backbone
 * This method should be called when view is no longer displayed
 */
Backbone.View.prototype.close = function () {
    'use strict';

    /**
     * Remove the view from the DOM and unbind jQuery element
     */
    this.remove();

    /**
     * Unbind any event attached by this view
     */
    this.unbind();

    /**
     * When onClose exist, execute it
     * The purpose of this code is to enable a custom on close method
     */
    if (this.onClose) {
        this.onClose();
    }

    return this;
};

/**
 * Listen to media
 * @description
 *
 * listen to media by add the listener to your 'initialize' function
 * inside a Backbone.View, like the example above:
 *
 *      initialize: function () {
 *          this.listenToMedia(this.onMediaChange);
 *      }
 *
 *      onMediaChange: function (media) {
 *          switch (media) {
 *              case 'desktop':
 *                  this.renderDesktop();
 *                  break;
 *          }
 *      }
 *
 * Once you turned-on the listener, you can use the method below to get
 * the current media type:
 *
 *      var myCurrentMedia = this.getCurrentMedia();
 *
 * @param {Function} callback
 * @returns {Backbone.Model}
 */
Backbone.View.prototype.listenToMedia = function (callback) {
    'use strict';

    /**
     * Fire media listener
     */
    this.fireMediaListener = function () {
        var media = this.getCurrentMedia();
        if (this.screenMedia !== media) {
            this.screenMedia = media;
            callback(this.screenMedia);
        }
    };

    /**
     * Register resize event
     */
    if (callback) {
        $(window).on('resize.listenToMedia', this.fireMediaListener.bind(this));
    }

    return this;
};

/**
 * Get the current media by resolution
 * @returns {*}
 */
Backbone.View.prototype.getCurrentMedia = function () {
    'use strict';

    /**
     * Find between media screen resolutions
     * @param {number} x - current resolution number
     * @param {Array} media - array win min and max resolution numbers
     * @returns {boolean}
     */
    function between(x, media) {
        return x >= media[0] && x <= media[1];
    }

    /**
     *
     */
    for (var device in globals.media.devices) {
        if (between(window.innerWidth, globals.media.devices[device])) {
            return device;
        }
    }

    return globals.media.default;
};

/**
 * Listen to any click outside the drop down
 * @param {boolean} listener - true = start listen, false - stop listen
 * @param {Function} callback - function to trigger on clickout of dropdown
 */
Backbone.View.prototype.dropdownListener = function (listener, callback) {
    'use strict';

    switch (listener) {
        case true:
            /*
             trigger any previous ddb-listener to close them
             before we create new one */
            $(window).trigger('click.ddb-listener');
            $(window).on('click.ddb-listener', callback);
            break;
        case false:
            $(window).off('click.ddb-listener');
    }
};

/**
 * Log actions on a mobile phone or tablet to a fixed div
 * use:
 * From any view this.mobileLog('text to log')
 */
Backbone.View.prototype.mobileLog = function (text) {
    'use strict';

    var $body = $('body');
    var logContainer = $body.find('.mobile-log');
    var innerText;

    if (!logContainer.length) {
        logContainer = $('<div class="mobile-log"></div>');
        logContainer.css({
            background: 'white',
            bottom: '0',
            'border-top': '2px solid #999',
            height: '30%',
            padding: '10px 10px 10px 20px',
            position: 'fixed',
            overflow: 'scroll',
            width: '100%',
            'z-index': '1050'
        });
    }

    text = [].slice.call(arguments, 0).join(' ');

    console.log(text);

    innerText = $('<div></div>');

    innerText.text(text);

    logContainer.append(innerText);

    $body.append(logContainer);
};
