(function (westcon) {
    'use strict';
    westcon.routers.practice = Backbone.Router.extend({

        /**
         * Routes
         * TODO: remove camel case dups
         */
        routes: {
            home: 'home',
            cloud: 'cloud',
            'global-deployment': 'global-deployment',
            globalDeployment: 'global-deployment',
            services: 'services',
            security: 'security',
            ucc: 'ucc',
            networking: 'networking',
            'data-center': 'data-center',
            dataCenter: 'data-center',
            '': 'home'
        }
    });
})(westcon);
