"use strict";

document.querySelectorAll(".accordion-dt:not([data-active])").forEach((el) => {
    el.dataset.active = true;
    el.addEventListener(
        "click",
        (event) => {
            //the target could be a child element, so check it is the button, and otherwise select the closest button
            const t = event.target.matches("button") ? event.target : event.target.closest("button");
            t.setAttribute("aria-expanded", t.getAttribute("aria-expanded") === "false" ? true : false);
            t.closest(".accordion-item").classList.toggle("is-open");
            const ariaCID = t.getAttribute("aria-controls");
            if (ariaCID) {
                const ariaEl = document.getElementById(ariaCID);
                if (ariaEl) {
                    ariaEl.setAttribute("aria-hidden", ariaEl.getAttribute("aria-hidden") === "false" ? true : false);
                    ariaEl.setAttribute("aria-expanded", ariaEl.getAttribute("aria-expanded") === "false" ? true : false);
                }
            }
        },
        globals.passiveSupported
    );
});
