(function (westcon) {
    'use strict';

    westcon.views.practice = Backbone.View.extend({

        events: {
            scroll: 'scroll',
            'click .practice-nav-list-item-link': 'navigateTo'

        },

        /**
         * Initialize
         */
        initialize: function () {
            this.$window = $(window);
            this.$arrow = $('.practice-arrow');
            this.$practiceMenu = $('.component-practice-nav');
            this.$contentAreas = $('.practice-content');
            this.toggleArrowShow();
            this.toggleArrowVisibility();
            this.scroll();

            this.router = new westcon.routers.practice();

            this.router.on('route', this.initializeRoute.bind(this));

            Backbone.history.start({
                root: this.getPath(),
                hashChange: true
            });

            this.addPartial();
            this.renderNavigation();
        },

        /**
         * Get path name
         *
         */
        getPath: function () {
            var pathArr = window.location.pathname.split('/');
            var path = pathArr[pathArr.length - 1];
            if (this.router.routes[path]) {
                pathArr.pop();
                return pathArr.join('/');
            }

            return window.location.pathname;
        },

        /**
         * Initialize Route
         * @param {string} route
         */
        initializeRoute: function (route) {
            this.route = route;
            this.render(arguments[1] || []);
        },

        /**
         * Hide contents
         */
        hideContents: function () {
            this.$contentAreas.removeClass('show');
        },

        /**
         * Add partial if none
         */
        addPartial: function () {
            if (!Backbone.history.fragment) {
                this.router.navigate('home', {trigger: true, replace: true});
            }
        },

        /**
         * Render contents
         */
        render: function () {
            this.hideContents();
            this.$('.practice-content[data-content="' + this.route + '"]').addClass('show');
        },

        /**
         * Render nav
         */
        renderNavigation: function () {
            this.navigation = new westcon.views.practiceNav({
                el: this.$practiceMenu,
                parentEl: this.$el
            });
        },

        /**
         * Hide or show arrow
         * for Ie 9
         */
        toggleArrowShow: function () {
            if (this.getScrollPos() === 0) {
                this.$arrow.removeClass('show');
            }

            if (this.getScrollPos() > 0) {
                this.$arrow.addClass('show');
            }
        },

        /**
         * Keep the right side sticky
         */
        scroll: function () {
            this.$window.bind('scroll.hidearrow', function () {
                this.$arrow.css('opacity', this.getScrollPos());
                this.toggleArrowShow();
                this.toggleArrowVisibility();
            }.bind(this));
        },

        /**
         * navigate to tab
         */
        navigateTo: function (e) {
            var target = $(e.currentTarget).attr('href');
            e.preventDefault();
            e.returnValue = false;
            this.router.navigate(target, { trigger: true, replace: true });
        },

        /**
         * Hide or show arrow
         */
        toggleArrowVisibility: function () {
            if (this.isArrowOut()) {
                this.$arrow.removeClass('show');
            }

            if (!this.isArrowOut()) {
                this.$arrow.addClass('show');
            }
        },

        /**
         * Is the arrow lower than the container bottom
         */
        isArrowOut: function () {
            return this.elBottomLoc() < this.viewPortBottom() - this.getArrowLocation();
        },

        /**
         * Get pactice arrow location
         */
        getArrowLocation: function () {
            return parseInt(this.$arrow.css('bottom'), 10);
        },

        /**
         * Get parents bottom location
         */
        elBottomLoc: function () {
            return this.$el.offset().top + this.$el.outerHeight(true);
        },

        /**
         * Get bottom of view port location
         */
        viewPortBottom: function () {
            return this.$window.scrollTop() + this.$window.height();
        },

        /**
         * Get scroll position
         */
        getScrollPos: function () {
            var pos = 1 - (this.$window.scrollTop() / 100);
            return pos < 0 ? 0 : pos;
        }
    });
}(westcon));
