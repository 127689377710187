(function (westcon) {
    "use strict";

    westcon.models.countrySelector = Backbone.Model.extend({
        currentCountry: null,
        originCountry: null,
        originLanguage: null,
        currentLanguageCode: null,

        /**
         * Initialize
         * @param {Object} props
         */
        initialize: function (props) {
            this.instanceUrl = props.url;
            // expect format like /uk/en/country-language-selector/_jcr_content.languageSelector.json
        },

        /**
         * Url
         * @returns {*}
         */
        url: function () {
            return this.instanceUrl;
        },

        /**
         * Sync After Fetch
         */
        postFetch: function () {
            //might be an instance when the instanceUrl has no countries. As backup, populate countries with something
            if (!this.get("countries").length) {
                this.set(
                    {
                        countries: [
                            {
                                "name": "Global",
                                "languages": [{ "name": "English", "url": "/global/en.html", "lang": "en-global" }],
                            },
                        ],
                    },
                    { silent: true }
                );
            }
            this.setCurrentCountry();
            this.setOriginCountry();
            this.setOriginLanguage();
        },

        /**
         * Set Current Country
         */
        setCurrentCountry: function () {
            //get just fetches the attributes, could replace with this?.attributes?.countries || [];
            const allCountries = this.get("countries");
            //find the selected country, otherwise the first
            this.currentCountry = allCountries.find((c) => c.selected) || allCountries[0];
        },

        /**
         * Set Origin Country
         */
        setOriginCountry: function () {
            this.originCountry = this.originCountry ? this.originCountry : this.currentCountry;
        },

        /**
         * Set Original Language
         */
        setOriginLanguage: function () {
            if (!this.originLanguage) {
                this.originLanguage = this.getSelectedLanguage();
            }
        },

        /**
         * Set All
         * @param {Object} options
         */
        setAll: function (options) {
            // options received as
            // "options": {
            //     "country": "France",
            //     "language": "English", //the preferred language
            // },

            const allCountries = this.get("countries");

            const countries = allCountries.map((country) => {
                //is this the target country?
                if (country.name === options.country) {
                    //is the target language available in the newly selected country?
                    const foundLang = country.languages.find((language) => language.name === options.language) || false;
                    //otherwise select the first available language from target country
                    const firstLang = country.languages.length ? country.languages[0] : {};
                    const langToSelect = foundLang ? foundLang : firstLang;

                    this.currentLanguageCode = langToSelect.lang;

                    const newLangs = country.languages.map((language) =>
                        Object.assign(language, { selected: language.name === langToSelect.name })
                    );
                    return {
                        name: country.name,
                        languages: newLangs,
                        selected: true,
                    };
                }

                return {
                    name: country.name,
                    languages: country.languages.map((l) => Object.assign(l, { selected: false })),
                    selected: false,
                };
            });

            this.set(
                {
                    countries: countries,
                },
                { silent: true }
            );
            this.trigger("change");
        },

        /**
         * Get Selected Languages
         * @param {string} country - optional
         * @returns {*|Array}
         */
        getLanguages: function (country) {
            const allCountries = this.get("countries");
            const countryToFind = country ? country : this.currentCountry.name;
            const foundCountry = allCountries.find((c) => c.name === countryToFind);
            return foundCountry.languages ? foundCountry.languages : [];
        },

        /**
         * Get Selected Language
         * @returns {*}
         */
        getSelectedLanguage: function () {
            const allLangs = this.getLanguages();
            return allLangs.find((l) => l.selected) || allLangs[0];
        },

        /**
         * Function that finds the first language key that starts with the input string, returns the full key if found.
         * @param {string} lang
         * @returns {string}
         */
        findLangThatStartsWith: function (lang = "en") {
            for (var k in this.get("i18n")) {
                if (Object.prototype.hasOwnProperty.call(k, "k") && k.indexOf(lang) === 0) {
                    return k;
                }
            }
        },

        /**
         * Get the code of the currently selected language (i.e. 'en-us' or 'pt-br-global')
         * @returns {*}
         */
        getCurrentLanguageCode: function () {
            if (this.currentLanguageCode) {
                return this.currentLanguageCode;
            }

            // first find the i18n element which is selected
            var key = _.findKey(this.get("i18n"), {
                selected: true,
            });

            if (!key) {
                // if that didn't exist, attempt to find a key in the i18n list, which starts with the html.lang attribute
                key = this.findLangThatStartsWith(globals.langCode);
            }

            if (!key) {
                // If still not found, default to English
                key = this.findLangThatStartsWith("en");
            }

            this.currentLanguageCode = key;
            return key;
        },

        /**
         * Get Language Url
         * @param {string} country
         * @param {string} language
         * @returns {*}
         */
        getLanguageUrl: function (country = "Global", language = "en-global") {
            //!adding defaults to avoid issues
            const foundLangs = this.getLanguages(country);
            const foundURL = foundLangs ? foundLangs.find((lang) => lang.name === language) : false;
            return foundURL && foundURL.url ? foundURL.url : "/global/en.html";

            // return _.findWhere(this.getLanguages(country), {
            //     name: language,
            // }).url;
        },

        /**
         * Is Country Changed
         * @param {string} country
         * @returns {boolean}
         */
        isCountryChanged: function (country) {
            return this.originCountry.name !== country;
        },

        /**
         * Is Language Changed
         * @param {string} language
         * @returns {boolean}
         */
        isLanguageChanges: function (language) {
            return this.originLanguage.name !== language;
        },

        /**
         * Get i18n language labels
         */
        getI18n: function () {
            if (Object.prototype.hasOwnProperty.call(this, "originDictionary")) {
                return this.originDictionary;
            }

            const curLang = this.getCurrentLanguageCode();
            const i18n = this.get("i18n");
            //look for the language code in the keys and reset to global-en if not found
            let newDictionary = Object.prototype.hasOwnProperty.call(i18n, curLang)
                ? i18n[curLang]
                : Object.prototype.hasOwnProperty.call(i18n, "global-en")
                ? i18n["global-en"]
                : i18n[0];
            //!other wise use first - flawed

            this.originDictionary = newDictionary;
            return newDictionary;
        },
    });
})(westcon);
