(function (westcon) {
    "use strict";

    westcon.views.partnerCentral = Backbone.View.extend({
        initialize: function () {
            const applications = document.querySelector(".partner-central-applications");
            const pcIcon = document.querySelector("[data-logged-in-href]"); //pcIcon is the partner central icon in the header

            if (applications instanceof HTMLElement && pcIcon instanceof HTMLElement) {
                try {
                    const fetchLogInStatus = fetch(this.el.dataset.userServlet);

                    fetchLogInStatus
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error(`HTTP error: ${response.status}`);
                            }
                            return response.json();
                        })
                        .then((data) => {
                            if ("groups" in data && data.groups.length && data.name !== "anonymous") {
                                pcIcon.href = pcIcon.dataset.loggedInHref;
                                pcIcon.classList.add("logged-in");

                                //listen for general clicks in the window and remove the hover status on applications
                                document.addEventListener(
                                    "click",
                                    () => {
                                        applications.classList.remove("hover");
                                    },
                                    globals.passiveSupported
                                );
                                pcIcon.addEventListener("click", (event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    applications.classList.add("hover");
                                    //close any potentially open popups
                                    globals.closePopup.search;
                                    globals.closePopup.language;
                                });

                                //this looks at the feature application components to change
                                //register into open
                                document.querySelectorAll("[data-partner-central-application]").forEach((element) => {
                                    var applicationId = element.dataset.partnerCentralApplication;
                                    if (data.groups.includes(applicationId)) {
                                        element.classList.remove("no-access");
                                    }
                                });

                                //update the hrefs for the mega menu
                                document.querySelectorAll(".mega-menu a[href*='partner-central']").forEach((element) => {
                                    element.setAttribute(
                                        "href",
                                        element.getAttribute("href").replace("partner-central", "partnercentral")
                                    );
                                });
                            }
                        })
                        .catch((error) => {
                            console.error(`Error during fetch of log in status: ${error}`);
                        });
                } catch (error) {
                    console.error(`Could not fetch log in status: ${error}`);
                }
            }
        },
    });
})(westcon);
//previously implemented code kept as reference prior to visual refresh release
//can delete after regression testing of new release expected April 2023
// (function (westcon) {
//     "use strict";

//     westcon.views.partnerCentral = Backbone.View.extend({
//         initialize: function () {
//             const applications = document.querySelector(".partner-central-applications");
//             const link = document.querySelector("[data-logged-in-href]");

//             if (applications instanceof HTMLElement && link instanceof HTMLElement) {
//                 $.ajax({
//                     url: this.el.dataset.userServlet,
//                     cache: false,
//                     success: function (data) {
//                         console.log("successfully called partner central", data);
//                         if (data.name !== "anonymous") {
//                             link.href = link.dataset.loggedInHref;
//                             link.classList.add("logged-in");

//                             document.addEventListener(
//                                 "click",
//                                 function () {
//                                     applications.classList.remove("hover");
//                                 },
//                                 globals.passiveSupported
//                             );
//                             link.addEventListener("click", function (event) {
//                                 event.preventDefault();
//                                 event.stopPropagation();
//                                 applications.classList.add("hover");
//                                 globals.closePopup.search;
//                                 globals.closePopup.language;
//                             });

//                             document.querySelectorAll("[data-partner-central-application]").forEach(function (element) {
//                                 var applicationId = element.dataset.partnerCentralApplication;
//                                 if (data.groups.includes(applicationId)) {
//                                     element.classList.remove("no-access");
//                                 }
//                             });
//                         }
//                     },
//                 });
//             }
//         },
//     });
// })(westcon);
