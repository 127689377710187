"use strict";
// the ContentSearchServlet will only process query and currentPage parameters.
// the ContentSearchService will only provide 10 results at a time
class SearchResultsClass {
    constructor() {
        this.url = window.location.pathname.replace(".html", "") + globals.api.content;
        //e.g. "uk/en/search/_jcr_content.json";
        this.searchField = document.getElementById("search-field");
        this.tabsContainer = document.getElementById("tabs-section");
        this.searchResultsContainer = document.getElementById("search-results-container");
        this.searchForm = document.getElementById("search-form");
        this.resetBtn = document.getElementById("reset-search");
        this.query = {
            searchQuery: { query: "", currentPage: "1" },
            pagination: {
                currentPage: 1,
                totalPages: 1,
                totalResults: 0,
                first: "",
                previous: "",
                next: "",
                last: "",
            },
            results: [],
        };
        this.query.searchQuery = this.getHashParameters();
        this.query.pagination.currentPage = parseInt(this.query.searchQuery.currentPage);
        this.resultsQty = document.querySelector(".results-qty");
        this.paginationContainer = document.querySelector(".pagination-container nav");
        this.articlesContainer = document.querySelector(".articles-container");
        this.hashUpdating = false;
        //check the following exist before initializing
        if (this.searchField && this.tabsContainer && this.searchResultsContainer && this.searchForm) {
            this.init();
        }
    }
    getHashParameters() {
        //the url of the search page is received as /sg/en/search.html#query=partner&currentPage=1
        //but could feasibly be /sg/en/search.html
        const currentParams = new URLSearchParams(window.location.hash.replace("#", "?"));
        return { query: currentParams.get("query") || "", currentPage: currentParams.get("currentPage") || "1" };
    }
    setHashParameters() {
        window.location.hash = `#query=${this.query.searchQuery.query}&currentPage=${this.query.pagination.currentPage}`;
    }
    init() {
        //look for the pagination translations, stored in the nav element
        //<nav data-previous="Précédent" data-next="Suivant" data-first="Première page" data-last="Dernière page"></nav>
        const words = this.paginationContainer
            ? this.paginationContainer.dataset
            : { first: "", next: "", last: "", previous: "" };
        this.query.pagination.first = words.first || "First";
        this.query.pagination.previous = words.previous || "Previous";
        this.query.pagination.next = words.next || "Next";
        this.query.pagination.last = words.last || "Last";
        // –––––––––––––––––––––––––––––––––––––––––––––––––––
        // Implement fetching and rendering of article results
        // –––––––––––––––––––––––––––––––––––––––––––––––––––
        //put in the query into the searchInput field
        this.searchField.value = this.query.searchQuery.query;
        //fetches the first results, as extracted from the page URL
        //(provided the search query is not empty)
        if (this.query.searchQuery.query) this.fetchSearchResults(this.query.pagination.currentPage);
        // Add event listeners for submitting the form (pressing enter)
        // and clicking the reset button
        this.searchForm.addEventListener("submit", (e) => {
            e.preventDefault();
            this.submitSearch();
        });
        if (this.resetBtn) {
            this.resetBtn.addEventListener("click", () => this.resetSearch());
        }
        // if the window hash changes, update the results
        window.addEventListener("hashchange", () => {
            console.log("hashchange");
            //has the user changed the hash parameters themselves?
            if (!this.hashUpdating) {
                console.log("manual hash change");
                const newParams = this.getHashParameters();
                this.query.searchQuery = newParams;
                this.query.pagination.currentPage = parseInt(this.query.searchQuery.currentPage);
                this.resetAllResults();
                this.fetchSearchResults();
            }
        });
        // Add more event listeners for pagination and tab switching
    }
    submitSearch() {
        if (this.searchField.value) {
            this.resetAllResults();
            this.query.searchQuery.query = this.searchField.value;
            this.setHashParameters();
            this.fetchSearchResults();
        }
    }
    resetSearch() {
        this.searchField.value = "";
        this.resetAllResults();
    }
    changePage(newPage) {
        this.hashUpdating = true;
        this.query.pagination.currentPage = newPage;
        this.setHashParameters();
        this.fetchSearchResults(newPage);
    }
    // –––––––––––––––––––––––––––––––––––––––––––––––––––
    // Fetch performs a single fetch of results
    // –––––––––––––––––––––––––––––––––––––––––––––––––––
    fetchSearchResults(page = 1) {
        try {
            const params = new URLSearchParams({ query: this.query.searchQuery.query, currentPage: page.toString() });
            const fetchResults = fetch(`${this.url}?${params}`);
            fetchResults
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then((result) => {
                    const loweredTotalPages = Math.max(result.pagination.totalPages - 1, 1);
                    if (result.results.length) {
                        this.query.results = result.results;
                        //adding in Math.max because there is a problem with the search results
                        //where it never populates the results on the last page, for anything after currentPage = 1
                        //therefore, fixing it by lowering the totalPages by 1
                        this.query.pagination.totalPages = loweredTotalPages;
                        this.query.pagination.totalResults = result.pagination.totalResults;
                        this.renderArticleResults();
                        this.hashUpdating = false;
                    } else {
                        if (loweredTotalPages < this.query.pagination.currentPage) {
                            //if the searched page was too higher than available, then reset back to page 1
                            this.changePage(1);
                        } else {
                            throw new Error("Problem with the results");
                        }
                    }
                });
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    // ––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
    // this will manage the low-priority auto-population of all results
    // ––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
    // async fetchAllResults(): Promise<void> {
    //! update with  new AbortController();
    //     // Fetch the first page
    //     const firstPageResponse = await this.fetchSearchResults(this.query.pagination.currentPage);
    //     this.query.results.push(...firstPageResponse.results);
    //     const totalPages = firstPageResponse.pagination.totalPages;
    //     // Prepare an array of promises for all remaining pages
    //     const fetchPromises: Promise<ArticleResults>[] = [];
    //     for (let i = 1; i <= totalPages; i++) {
    //         if (i !== this.query.pagination.currentPage) {
    //             fetchPromises.push(this.fetchSearchResults(i));
    //         }
    //     }
    //     // Fetch all remaining pages with Promise.all
    //     const allRemainingPages = await Promise.all(fetchPromises);
    //     // Append results of each remaining page to articleResults
    //     allRemainingPages.forEach((response) => {
    //         this.query.results.push(...response.results);
    //     });
    // }
    resetAllResults() {
        this.query.pagination.currentPage = 1;
        this.query.pagination.totalPages = 1;
        this.query.pagination.totalResults = 0;
        this.query.results = [];
        this.renderArticleResults();
    }
    createButton({ ariaLabel = "", classList = "", text, arrows = 0, onClick }) {
        const li = document.createElement("li");
        const btn = document.createElement("button");
        btn.setAttribute("aria-label", ariaLabel);
        if (classList) li.classList.add(...classList.split(" "));
        btn.textContent = text;
        for (let j = 0; j < arrows; j++) {
            btn.append(Object.assign(document.createElement("div"), { classList: "arrow" }));
        }
        btn.addEventListener("click", onClick);
        li.append(btn);
        return li;
    }

    renderArticleResults() {
        // –––––––––––––––––––––––––––––––––––––––––––––––––––
        // Update the pagination
        // –––––––––––––––––––––––––––––––––––––––––––––––––––
        //cache the current page
        const curPage = this.query.pagination.currentPage,
            totalPages = this.query.pagination.totalPages;

        //first, clear the pagination container
        this.paginationContainer.innerHTML = "";
        //only render the pagination if the results exceeds more than one page
        if (totalPages > 1) {
            //pagination buttons will be structured like ul>li>button
            const nav = document.createElement("ul");
            nav.classList.add("pagination");
            //determine the pagination numbers that will be shown
            const range = this.getPaginationRange();
            // Prepare FIRST and PREVIOUS buttons, if necessary
            if (totalPages > 5 && curPage > 1) {
                const first = this.createButton({
                    ariaLabel: this.query.pagination.first,
                    classList: "prev",
                    text: "",
                    arrows: 2,
                    onClick: () => this.changePage(1),
                });
                nav.append(first);
            }
            //always add the previous button
            const prev = this.createButton({
                ariaLabel: this.query.pagination.previous,
                classList: `prev${curPage === 1 ? " disabled" : ""}`,
                text: "",
                arrows: 1,
                onClick: () => this.changePage(curPage - 1),
            });
            nav.append(prev);
            // Render all the number buttons
            for (let i = range.start; i <= range.end; i++) {
                const cur = i === curPage ? "current" : "";
                nav.append(this.createButton({ text: i.toString(), classList: cur, onClick: () => this.changePage(i) }));
            }
            // Render next and last buttons
            const next = this.createButton({
                ariaLabel: this.query.pagination.next,
                classList: `next${curPage === totalPages ? " disabled" : ""}`,
                text: "",
                arrows: 1,
                onClick: () => this.changePage(curPage + 1),
            });
            nav.append(next);
            if (totalPages > 5 && curPage < totalPages) {
                const last = this.createButton({
                    ariaLabel: this.query.pagination.last,
                    classList: "last",
                    text: "",
                    arrows: 2,
                    onClick: () => this.changePage(totalPages),
                });
                nav.append(last);
            }
            this.paginationContainer.append(nav);
        }
        // –––––––––––––––––––––––––––––––––––––––––––––––––––
        // Update the quantity indicator
        // –––––––––––––––––––––––––––––––––––––––––––––––––––
        //e.g. "Items 11 to 20 of 256 total"
        const { itemsText = "Items", totalText = "total", toText = "to", ofText = "of" } = this.resultsQty.dataset;
        if (this.query.pagination.totalResults)
            this.resultsQty.innerText = `${itemsText} ${(curPage - 1) * 10 + 1} ${toText} ${Math.min(
                (curPage - 1) * 10 + 10,
                this.query.pagination.totalResults
            )} ${ofText} ${this.query.pagination.totalResults} ${totalText}`;
        // –––––––––––––––––––––––––––––––––––––––––––––––––––
        // Populate the articles
        // –––––––––––––––––––––––––––––––––––––––––––––––––––
        //clear the results
        this.articlesContainer.innerHTML = "";
        //repopulate search results from the data
        this.query.results.forEach((result) => {
            const article = document.createElement("article");
            if (result.headline) {
                const heading = document.createElement("h2");
                if (result.url) {
                    const link = Object.assign(document.createElement("a"), { href: result.url, textContent: result.headline });
                    heading.append(link);
                } else {
                    heading.innerText = result.headline;
                }
                article.append(heading);
            }
            if (result.description) {
                const desc = Object.assign(document.createElement("p"), { innerText: result.description });
                article.append(desc);
            }
            this.articlesContainer.append(article);
            this.searchField.scrollIntoView({ behavior: "smooth", block: "center" });
        });
        //structure of the result
        // <article class="results-article-item">
        //     <h2>
        //         <a></a>
        //     </h2>
        //     <p></p>
        // </article>;
    }
    getPaginationRange() {
        //prevents displaying more than 5 items and centers the current page in the range
        const { currentPage, totalPages } = this.query.pagination;
        const start = totalPages <= 5 || currentPage <= 3 ? 1 : currentPage > totalPages - 2 ? totalPages - 4 : currentPage - 2;
        const end =
            totalPages <= 5 ? totalPages : currentPage <= 3 ? 5 : currentPage > totalPages - 2 ? totalPages : currentPage + 2;
        return { start, end };
    }
}
if (document.getElementById("search-results-container")) {
    new SearchResultsClass();
}
