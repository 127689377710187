"use strict";

(function (westcon) {
    westcon.views.header = Backbone.View.extend({
        events: {
            "click .mobile-burger-menu": "toggleBurger",
            "click .primary-nav-link": "openMenuItem",
            "click .menu-open-overlay": "closeMenu",
        },

        initialize: function () {
            this.burgerMenu = document.querySelector(".mobile-burger-menu");
            this.menuOverlay = document.querySelector(".menu-open-overlay");

            //! what about if you use the keyboard to blur away from the menu?
        },

        toggleBurger: function () {
            document.body.classList.toggle("menu-open");
            document.querySelectorAll(".header-lower-navigation-item").forEach((link) => link.classList.remove("open"));
        },

        openMenuItem: function (event) {
            if (event.target && event.target instanceof Element) {
                const target = event.target.closest(".header-lower-navigation-item") || false;

                if (!target) return;

                if (target.classList.contains("open")) {
                    target.classList.remove("open");
                    //if sandwich is not visible, then also remove menu-open from body
                    if (!this.burgerMenu || (this.burgerMenu && this.burgerMenu.clientHeight === 0))
                        document.body.classList.remove("menu-open");
                } else {
                    document.querySelectorAll(".header-lower-navigation-item").forEach((link) => link.classList.remove("open"));
                    //checks if the .mega-menu element exists below the nav item
                    if (target.querySelector(".mega-menu")) target.classList.add("open");
                    document.body.classList.add("menu-open");
                }
            }
            //close any popups, i.e. country selector, search, partnercentral
            globals.closePopup.closeAll();
        },

        closeMenu: function () {
            //remove the open menu if click in the overlay area
            document.body.classList.remove("menu-open");
            document.querySelectorAll(".header-lower-navigation-item.open").forEach((el) => el.classList.remove("open"));
        },
    });
})(westcon);
