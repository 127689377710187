//––––––––––––––––––––––––––––––––––––––––
//  Carousel
//––––––––––––––––––––––––––––––––––––––––
document
    .querySelectorAll("body:not([data-edit-mode]) .carousel:not([data-activated]) .carousel-slides")
    .forEach((carousel, indexCarousel) => {
        const containerID = "carousel-" + indexCarousel;
        if (carousel instanceof HTMLElement) {
            carousel.dataset.id = containerID;
        }
        const slides = CSS.supports("*:has(div)")
            ? carousel.querySelectorAll(".contentcarouselslide:not(:has([data-has-expired]))")
            : Array.from(carousel.querySelectorAll(".contentcarouselslide")).filter(
                  (s) => !s.innerHTML.includes("data-has-expired")
              );

        //create the carousel controller
        const controls = document.createElement("div");
        controls.classList.add("carousel-controls");
        //add a unique identifier to the controls div
        // controls.dataset.id = containerID;
        if (carousel.parentElement) {
            carousel.parentElement.appendChild(controls);
        }

        //navButtons holds each of the nav circles underneath the carousel
        const navButtons = [];

        const controlClickHandler = (event) => {
            event.preventDefault();

            let slideToOutro;

            //reset active and outro for all slides
            slides.forEach((slide) => {
                if (slide.classList.contains("active")) {
                    slideToOutro = slide;
                }
                slide.classList.remove("active", "outro");
            });
            if (slideToOutro !== undefined && slideToOutro instanceof HTMLElement) {
                slideToOutro.classList.add("outro");
            }

            //apply the active class to the target slide
            const currentTarget = document.querySelector(event.target.getAttribute("href"));

            if (currentTarget instanceof HTMLElement) {
                currentTarget.classList.add("active");
            }

            //remove the active state on any control indicator
            controls.querySelectorAll("a").forEach((control) => control.classList.remove("active"));

            //update the target on the next button
            const curIndex = parseInt(currentTarget.dataset.id, 10);
            const nextIndex = curIndex >= slides.length - 1 ? 0 : curIndex + 1;
            nextButton.href = `[data-id='${containerID}'] [data-id='${nextIndex}']`;

            //add the active state to the clicked control button
            navButtons[curIndex].classList.add("active");
        };
        if (slides.length === 1) {
            slides[0].classList.add("active");
        }
        if (slides.length > 1) {
            //create the navigation buttons underneath the carousel
            slides.forEach((slide, indexSlide) => {
                slide.dataset.id = indexSlide;
                //create clickable control
                const clickTarget = Object.assign(document.createElement("a"), {
                    href: `[data-id='${containerID}'] [data-id='${indexSlide}']`,
                    innerText: indexSlide,
                    tabIndex: -1,
                    onclick: (e) => controlClickHandler(e),
                });
                controls.appendChild(clickTarget);
                //also add to the array of navButtons for easy reference later
                navButtons.push(clickTarget);
                if (indexSlide === 0) {
                    //activate the first slide so that it shows
                    slide.classList.add("active");
                    clickTarget.classList.add("active");
                }
            });
        }

        //create a next arrow button
        const nextButton = Object.assign(document.createElement("a"), {
            className: "next-button",
            href: `[data-id='${containerID}'] [data-id='1']`, //1 is second, as list starts at 0
            innerText: " ",
            tabIndex: -1,
            onclick: (e) => controlClickHandler(e),
        });

        if (slides.length > 1) {
            carousel.appendChild(nextButton);
        }

        //handler for detecting a touch swipe to move the carousel right
        const gesture = {
            x: [],
            y: [],
        };

        carousel.addEventListener(
            "touchstart",
            function (e) {
                for (let i = 0; i < e.touches.length; i++) {
                    gesture.x.push(e.touches[i].clientX);
                    gesture.y.push(e.touches[i].clientY);
                }
            },
            globals.passiveSupported
        );
        carousel.addEventListener(
            "touchmove",
            function (e) {
                for (let i = 0; i < e.touches.length; i++) {
                    gesture.x.push(e.touches[i].clientX);
                    gesture.y.push(e.touches[i].clientY);
                }
            },
            globals.passiveSupported
        );
        carousel.addEventListener(
            "touchend",
            function () {
                const tolerance = 100;
                var xTravel = gesture.x[gesture.x.length - 1] - gesture.x[0],
                    yTravel = gesture.y[gesture.y.length - 1] - gesture.y[0];
                // if (xTravel < tolerance && xTravel > -tolerance && yTravel < -tolerance) {
                //   gesture.match = 'Swiped Up';
                // }
                // if (xTravel < tolerance && xTravel > -tolerance && yTravel > tolerance) {
                //   gesture.match = 'Swiped Down';
                // }
                if (yTravel < tolerance && yTravel > -tolerance && xTravel < -tolerance) {
                    nextButton.click();
                }
                // if (yTravel < tolerance && yTravel > -tolerance && xTravel > tolerance) {
                //   nextButton.click();
                //   console.log(1);
                // }

                gesture.x = [];
                gesture.y = [];
                xTravel = yTravel = "";
            },
            globals.passiveSupported
        );

        //add activated class to the carousel to indicate the javascript has been executed
        carousel.closest(".carousel").dataset.activated = true;
    });
