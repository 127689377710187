// (function (westcon, $) {
//     var params = getAllUrlParams();
//     var url = location.protocol + "//" + location.host + location.pathname;

//     function getAllUrlParams() {
//         var queryString = window.location.href.split("?")[1];

//         var obj = {};

//         if (queryString) {
//             queryString = queryString.split("#")[0];

//             var arr = queryString.split("&");

//             for (var i = 0; i < arr.length; i++) {
//                 var a = arr[i].split("=");
//                 var paramName = a[0];
//                 var paramValue = typeof a[1] === "undefined" ? true : a[1];

//                 if (paramName.match(/\[(\d+)?\]$/)) {
//                     var key = paramName.replace(/\[(\d+)?\]/, "");
//                     if (!obj[key]) {
//                         obj[key] = [];
//                     }

//                     if (paramName.match(/\[\d+\]$/)) {
//                         var index = /\[(\d+)\]/.exec(paramName)[1];
//                         obj[key][index] = paramValue;
//                     } else {
//                         obj[key].push(paramValue);
//                     }
//                 } else {
//                     if (!obj[paramName]) {
//                         obj[paramName] = paramValue;
//                     } else if (obj[paramName] && typeof obj[paramName] === "string") {
//                         obj[paramName] = [obj[paramName]];
//                         obj[paramName].push(paramValue);
//                     } else {
//                         obj[paramName].push(paramValue);
//                     }
//                 }
//             }
//         }

//         return obj;
//     }

//     function filter(name, value) {
//         params[name] = value;
//         window.location.href = url + "?" + encodeParams();
//     }

//     function encodeParams() {
//         var urlParams = "";
//         for (var key in params) {
//             if (urlParams != "") {
//                 urlParams += "&";
//             }
//             urlParams += key + "=" + encodeURIComponent(params[key]);
//         }
//         return urlParams;
//     }
(function (westcon, $) {
    westcon.views.blogSearchModule = Backbone.View.extend({
        initialize: function () {
            this.listing = $(".blog-search-module__listing");
            this.itemTemplate = _.template($("#blog-search-module__item-template").html());

            const button = document.querySelector(".blog-search-module__load-more");
            this.button = button;
            const searchInput = document.querySelector(".search-ux-search input");
            const searchFetchUrl = button ? button.dataset.url : "";

            if (button && searchFetchUrl) {
                button.addEventListener(
                    "click",
                    () => {
                        console.log("clicked");
                        button.setAttribute("loading", true);
                        const currentPage = parseInt(button.dataset.currentPage || 1);

                        var data = {
                            text: searchInput ? searchInput.value : "",
                            currentpage: currentPage + 1,
                            pagesize: button.dataset.pageSize || 1,
                        };
                        console.log(searchFetchUrl, data);
                        $.post(searchFetchUrl, data, this.onLoad.bind(this));
                        // fetchMoreSearchResults(data);
                    },
                    globals.passiveSupported
                );
            }
            //not working as the $.post is working at the moment :-s
            // function fetchMoreSearchResults(data) {
            //     console.log("fetching", data);

            //     try {
            //         const params = new URLSearchParams(data);
            //         const fetchBlogs = fetch(`${searchFetchUrl}?${params}`);
            //         fetchBlogs
            //             .then((response) => {
            //                 if (!response.ok) {
            //                     throw new Error(`HTTP error: ${response.status}`);
            //                 }
            //                 return response.json();
            //             })
            //             .then((data) => {
            //                 console.log(data);
            //                 button.removeAttribute("loading");
            //             })
            //             .catch((error) => {
            //                 console.error(`Error during fetch of more search results: ${error}`);
            //                 button.removeAttribute("loading");
            //             });
            //     } catch (error) {
            //         console.error(`Could not fetch more search results: ${error}`);
            //         button.removeAttribute("loading");
            //     }
            // }
        },

        onLoad: function (response) {
            this.button.removeAttribute("loading");

            if (!response) {
                return;
            }
            if (response.results) {
                this.renderResults(response.results);
            }
            if (response.pagination) {
                var currentPage = parseInt(response.pagination.currentPage);
                if (currentPage === parseInt(response.pagination.totalPages)) {
                    this.button.setAttribute("disabled");
                    return;
                }
            }
        },
        renderResults: function (results) {
            for (var i = 0; i < results.length; i++) {
                var item = this.itemTemplate(results[i]);
                this.listing.append(item);
            }
        },
    });
    if (document.querySelector(".blog-search-module__listing")) {
        new westcon.views.blogSearchModule();
    }
})(westcon, jQuery);
//     westcon.views.blogSearchModule = Backbone.View.extend({
//         initialize: function () {
//             this.button = $(".blog-search-module__load-more");
//             this.loadMoreUrl = this.button.data().url;
//             this.pageSize = this.button.data().pageSize;
//             this.listing = $(".blog-search-module__listing");
//             this.text = $(".blog-search-module__search-input");
//             this.itemTemplate = _.template($("#blog-search-module__item-template").html());
//             this.scrollTo = $(".blog-search-module__scroll-to");
//             this.dropdowns = $(".blog-search-module__dropdown");
//             this.search = $(".blog-search-module__search");
//             this.searchButton = $(".blog-search-module__search-button");
//             this.clearButton = $(".blog-search-module__filters-clear");
//             this.dropDownOption = $(".blog-search-module__dropdown-option");
//         },
//         begin: function () {
//             if (this.scrollTo.length) {
//                 $("html, body").animate({
//                     scrollTop: scrollTo.offset().top,
//                 });
//             }
//             this.bindEvents();
//         },
//         bindEvents: function () {
//             this.button.click(this.onButtonClick.bind(this));
//             this.text.on("keypress", this.onTextKeyPress.bind(this));
//             this.searchButton.on("click", this.onSearchButtonClick.bind(this));
//             this.clearButton.on("click", this.onClearButtonClick.bind(this));
//             this.dropDownOption.on("click", this.onDropDownOptionClick);
//         },
//         onButtonClick: function () {
//             this.button.attr("loading", true);
//             var newPage = parseInt(this.button.data().currentPage) + 1;
//             this.button.data("current-page", newPage);
//             var data = {
//                 text: this.text.val(),
//                 currentpage: newPage,
//                 pagesize: this.pageSize,
//             };
//             $.post(this.loadMoreUrl, data, this.onLoad.bind(this));
//         },
//         onTextKeyPress: function (ev) {
//             if (ev.which == 13) {
//                 params = {};
//                 filter(this.search.data("filter"), ev.target.value);
//             }
//         },
//         onSearchButtonClick: function () {
//             params = {};
//             filter(this.search.data("filter"), this.text.val());
//         },
//         onClearButtonClick: function () {
//             window.location.href = url;
//         },
//         onDropDownOptionClick: function (e) {
//             e.preventDefault();

//             filter(e.target.closest(".blog-search-module__dropdown").dataset.filter, e.target.dataset.value);
//             // filter($(this).parents(".blog-search-module__dropdown").data("filter"), $(this).data("value"));
//         },
//         onLoad: function (response) {
//             this.button.attr("loading", false);
//             if (!response) {
//                 return;
//             }
//             if (response.results) {
//                 this.renderResults(response.results);
//             }
//             if (response.pagination) {
//                 var currentPage = parseInt(response.pagination.currentPage);
//                 if (currentPage === parseInt(response.pagination.totalPages)) {
//                     this.button.attr("disabled", "");
//                     return;
//                 }
//             }
//         },
//         renderResults: function (results) {
//             for (var i = 0; i < results.length; i++) {
//                 var item = this.itemTemplate(results[i]);
//                 this.listing.append(item);
//             }
//         },
//     });

//     document.addEventListener("DOMContentLoaded", () => {
//         if (document.getElementsByClassName("blog-search-module__filters-wrapper").length === 0) {
//             return;
//         }

//         var handler = new westcon.views.blogSearchModule();
//         handler.begin();

//         //add the .open class to the dropdown on click
//         //!need to revisit to better handle accessibility
//         document.querySelectorAll(".blog-search-module__dropdown").forEach((dropdown) =>
//             dropdown.addEventListener(
//                 "click",
//                 (event) => {
//                     event.stopPropagation();
//                     const list = dropdown.querySelector(".blog-search-module__dropdown-list");
//                     if (list) {
//                         list.classList.toggle("open");
//                     }
//                 },
//                 globals.passiveSupported
//             )
//         );

//         //remove the .open class on a click to reset the popup menu status
//         document.addEventListener("click", function () {
//             var dropdownToggles = document.getElementsByClassName("blog-search-module__dropdown-list");
//             for (var i = 0; i < dropdownToggles.length; i++) {
//                 dropdownToggles[i].classList.remove("open");
//             }
//         });
//     });
// })(westcon, jQuery);
