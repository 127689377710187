(function (westcon) {
    "use strict";

    westcon.views.resourceLibrary = Backbone.View.extend({
        /**
         * Initialize
         */
        begin: function () {
            this.element = $(".resource-library");
            this.techFilterCont = $(".technologies-filter");
            this.typesFilterCont = $(".types-filter");
            this.vendorsFilterCont = $(".vendors-filter");
            this.navigationCont = $(".navigation");
            this.filtersContainer = $(".filter-container");
            this.resultsCont = $(".resources-container__inner");
            this.navButton = $(".mobile_filter");
            this.filterContainer = $(".resource-library-filter-nav");
            this.noResultsCont = $(".no-results-container");
            this.resetBtn = $(".reset");
            this.selectLanguageVal = $(".language-select").val();
            this.selectLanguage = $(".language-select");
            this.closeFilterBtn = $(".resource-library-filter-nav__close-icon");
            this.seeMoreVendors = $(".see-more__container");
            this.searchInputText = $(".search-input__text");
            this.searchResults = $(".search-results");
            this.searchResultsText = $(".search-results__text");
            this.searchResultIcon = $(".search-result__icon");
            this.searchInputIcon = $(".search-input__icon");
            this.technologyResults = [];
            this.typeResults = [];
            this.vendorsResults = [];
            this.currentPage = 1;
            this.activeTechnology = [];
            this.activeType = [];
            this.activeVendor = [];
            this.totalPages = 0;
            this.viewImgBtn = $(".img-view");
            this.viewListBtn = $(".list-view");
            this.inputMinimumLenght = 3;
            this.searchInputValue = "";
            this.typingTimer = {};
            this.doneTypingInterval = 500;
            this.initialResults = true;

            this.vendorsLimitFilter = this.element.data().vendorListSize;
            this.resourcelibraryUrl = this.element.data().jsonUrl;
            this.country = this.element.data().country;
            this.pageSize = this.element.data().pageSize;
            this.listViewType = this.element.data().listViewType;

            //this.resourcelibraryUrl = 'http://dev-author.wgcloudconnect.com:4503/content/wcgcom/es/es/home/_jcr_content.resourcelibrary.json';

            this.techInitData = $(".technology-filters li[data-name]");
            this.typeInitData = $(".type-filters li[data-name]");
            this.vendorInitData = $(".vendor-filters li[data-name]");

            this.initTechnologyData = [];
            this.initTypeData = [];
            this.initVendorsData = [];

            /** getting init data value */
            function getDataValue(dataInit, dataArray) {
                $.map(dataInit, function (item) {
                    dataArray.push(item.dataset.name);
                });
            }

            getDataValue(this.techInitData, this.initTechnologyData);
            getDataValue(this.typeInitData, this.initTypeData);
            getDataValue(this.vendorInitData, this.initVendorsData);

            this.initData = {
                currentpage: this.currentPage,
                pagesize: this.pageSize,
                technologies: this.initTechnologyData.join("|"),
                vendors: this.initVendorsData.join("|"),
                types: this.initTypeData.join("|"),
                country: this.country,
                language: this.selectLanguageVal,
            };

            this.loadData(this.resourcelibraryUrl, this.initData);
        },

        /** updating data for Api call*/
        updateData: function (currentPage) {
            var page = 1;
            if (currentPage > 1) {
                page = currentPage;
            } else {
                page = 1;
            }

            var newData = {
                currentpage: page,
                pagesize: this.pageSize,
                technologies: this.activeTechnology.join("|"),
                vendors: this.activeVendor.join("|"),
                types: this.activeType.join("|"),
                country: this.country,
                language: this.selectLanguageVal,
                text: this.searchInputValue,
            };

            this.updateResults(this.resourcelibraryUrl, newData);
        },

        /** Clear Facets when language is change */
        clearFacets: function () {
            this.techFilterCont.empty();
            this.typesFilterCont.empty();
            this.vendorsFilterCont.empty();
            this.technologyResults = [];
            this.typeResults = [];
            this.vendorsResults = [];
        },

        /** Reset Text input value */
        clearTextInput: function () {
            this.searchInputValue = "";
            $(this.searchResultsText).text(this.searchInputValue);
            $(this.searchInputText).val(this.searchInputValue);
            $(this.searchResults).hide();
            $(this.searchInputIcon).hide();
        },

        /** handling active state from filtering options */
        getActiveOptions: function (currentPage) {
            const activeOptions = $("li.active");
            this.activeTechnology = [];
            this.activeType = [];
            this.activeVendor = [];

            $.map(
                activeOptions,
                function (options) {
                    if (options.dataset.category === "technology") {
                        this.activeTechnology.push(options.dataset.itemName);
                    }

                    if (options.dataset.category === "type") {
                        this.activeType.push(options.dataset.itemName);
                    }

                    if (options.dataset.category === "vendor") {
                        this.activeVendor.push(options.dataset.itemName);
                    }
                }.bind(this)
            );

            if (currentPage > 1) {
                this.updateData(currentPage);
            } else {
                this.updateData(1);
            }
        },

        /** handling active state from filtering options */
        handleActiveOptions: function (e) {
            const target = $(e.target);
            const toggleClassName = "active";
            const activeOptionsLengthBeforeChange = target
                .closest("ul")
                .children("." + toggleClassName).length;
            const listNumber = target.closest("ul").children().length;

            target.closest("li").toggleClass(toggleClassName);

            if (activeOptionsLengthBeforeChange === listNumber) {
                target.closest("ul").children().removeClass(toggleClassName);
                target.closest("li").addClass(toggleClassName);
            }

            const activeOptionsLengthAfterChange = target
                .closest("ul")
                .children("." + toggleClassName).length;

            if (activeOptionsLengthAfterChange === 0) {
                target.closest("ul").children().addClass(toggleClassName);
            }

            this.getActiveOptions();
        },

        /**handle next page */
        handlePagination: function (e) {
            var nextPage = e.currentTarget.children[2].dataset.nextPage;
            var prevPage = e.currentTarget.children[1].dataset.prevPage;

            if (e.target.classList.contains("next")) {
                if (nextPage <= this.totalPages) {
                    this.getActiveOptions(Number(nextPage));
                    this.currentPage = nextPage;
                }
            }

            if (e.target.classList.contains("prev")) {
                if (prevPage > 0) {
                    this.getActiveOptions(Number(prevPage));
                    this.currentPage = prevPage;
                }
            }
        },

        /** nav button  */
        handleNavButton: function () {
            this.filterContainer.toggleClass("mobile_hide");
            this.navButton.toggleClass("inactive");
        },

        /** hanlding select */
        handleSelect: function (e) {
            const languageVal = e.target.value;
            this.initialResults = true;
            this.selectLanguageVal = languageVal;

            $(this.selectLanguage).unbind();

            const newData = Object.assign({}, this.initData);
            newData.language = languageVal;

            this.clearFacets();
            this.clearTextInput();
            this.loadData(this.resourcelibraryUrl, newData);
        },

        /**handeling reset button */
        handleResetBtn: function () {
            var filterOptions = $(".filter-options");
            filterOptions.not("active").addClass("active");
            this.getActiveOptions();
        },

        /**handeling Show more Vendors */
        handleSeeMoreVendors: function () {
            const className = "active";
            $(this.seeMoreVendors).toggleClass(className);

            this.changeSeeMoreVendorsText(className);
        },

        /** Show or Hide Search Input icon */
        toggleSearchInputIcon: function (text) {
            if (text.length > 0) {
                $(this.searchInputIcon).show();
                return;
            }

            $(this.searchInputIcon).hide();
        },

        /**Show or Hide Search div */
        toggleSearchContainer: function (key) {
            if (
                (this.searchInputValue.length < this.inputMinimumLenght &&
                    key === 8) ||
                key === 46
            ) {
                $(this.searchResults).hide();
                this.getActiveOptions();
                return;
            }

            if (this.searchInputValue.length < this.inputMinimumLenght) {
                $(this.searchResults).hide();
                return;
            }

            this.getActiveOptions();
            $(this.searchResults).show();
        },

        /**handeling Input text field */
        handleSearchInputText: function (e) {
            const key = e.keyCode || e.charCode;
            this.searchInputValue = e.target.value;
            $(this.searchResultsText).text(this.searchInputValue);

            this.toggleSearchInputIcon(this.searchInputValue);

            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(
                this.toggleSearchContainer.bind(this, key),
                this.doneTypingInterval
            );
        },

        /**handeling Search times icon*/
        handleClearInput: function () {
            this.clearTextInput();
            this.getActiveOptions();
        },

        /**handeling Search times icon*/
        handleKeydownInputText: function () {
            clearTimeout(this.typingTimer);
        },

        /** adding events for filtering options*/
        addEventsListeners: function () {
            var filterOptions = $(".filter-options");

            $(filterOptions).on("click", this.handleActiveOptions.bind(this));
            $(this.navigationCont).on(
                "click",
                this.handlePagination.bind(this)
            );
            $(this.navButton).on("click", this.handleNavButton.bind(this));
            $(this.selectLanguage).on("change", this.handleSelect.bind(this));
            $(this.resetBtn).on("click", this.handleResetBtn.bind(this));
            $(this.closeFilterBtn).on("click", this.handleNavButton.bind(this));
            $(this.seeMoreVendors).on(
                "click",
                this.handleSeeMoreVendors.bind(this)
            );
            $(this.searchInputText).on(
                "keyup",
                this.handleSearchInputText.bind(this)
            );
            $(this.searchInputText).on(
                "keydown",
                this.handleKeydownInputText.bind(this)
            );
            $(this.searchResultIcon).on(
                "click",
                this.handleClearInput.bind(this)
            );
            $(this.searchInputIcon).on(
                "click",
                this.handleClearInput.bind(this)
            );
        },

        /** Change inner text when is active */
        changeSeeMoreVendorsText: function (className) {
            const seeMoreTextElement = $(".see-more__text");
            const inactiveText = "See More";
            const activeText = "See Less";
            const showVendorsClass = "show-vendors";
            const hiddenVendorsFilters = $(".vendors-filter .show-less");

            if (!$(this.seeMoreVendors).hasClass(className)) {
                $(seeMoreTextElement).text(inactiveText);
                $(hiddenVendorsFilters).removeClass(showVendorsClass);
                return;
            }

            $(hiddenVendorsFilters).addClass(showVendorsClass);

            $(seeMoreTextElement).text(activeText);
        },

        /** this is the filters template */
        filterTemplate: function (filterOptions, container, category) {
            var listItems = [];
            var classes = this.filtersContainer.data().filterClass;
            var initialResults = this.initialResults;

            $.map(filterOptions, function (item) {
                var li =
                    '<li class="' +
                    classes +
                    '" data-category=' +
                    category +
                    " data-item-name=" +
                    item.name +
                    ">" +
                    item.label +
                    ' <span>(<span class="item-count" data-item-name=' +
                    item.name +
                    " data-count=" +
                    item.count +
                    ">" +
                    item.count +
                    '</span><span class="item-total-count" data-total-count=' +
                    item.count +
                    "> / " +
                    item.count +
                    "</span>)</span></li>";

                if (initialResults && item.count > 0) {
                    listItems.push(li);
                }
            });

            container.html(listItems);
        },

        /**enable or disable btns for pagination */
        enableBtns: function (currentPage, totalPages) {
            if (currentPage === 1) {
                $(".prev").prop("disabled", true);
            }

            if (currentPage >= totalPages) {
                $(".next").prop("disabled", true);
            }
        },

        /** this is the pagination template */
        paginationTemplate: function (paginationData) {
            const currentPage = paginationData.currentPage;
            const pageSize = paginationData.pageSize;
            const totalResults = paginationData.totalResults;
            const totalPages = paginationData.totalPages;
            const counter = pageSize * currentPage;
            const navDataSet = this.navigationCont.data();
            const prevClass = navDataSet.prevClass;
            const nextClass = navDataSet.nextClass;
            const prevText = navDataSet.prevText;
            const nextText = navDataSet.nextText;
            const rightArrow =
                '<svg class="svg-inline--fa fa-caret-right fa-w-6" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" data-fa-i2svg=""><path fill="currentColor" d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z"></path></svg>';
            const leftArrow =
                '<svg class="svg-inline--fa fa-caret-left fa-w-6" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" data-fa-i2svg=""><path fill="currentColor" d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z"></path></svg>';
            var pageTotals = pageSize;

            this.totalResults = totalResults;

            if (totalPages === 0) {
                this.navigationCont.hide();
                return;
            }

            this.navigationCont.show();

            if (totalResults < pageSize && this.currentPage < totalPages) {
                pageTotals = totalResults;
            }

            if (this.currentPage >= 2 && this.currentPage < totalPages) {
                pageTotals = counter;
            }

            if (currentPage === totalPages) {
                pageTotals = totalResults;
            }

            const pageCounter =
                "<p data-page-size=" +
                pageSize +
                " data-total-pages=" +
                totalPages +
                " data-total-results=" +
                totalResults +
                ">" +
                (counter - pageSize + 1) +
                " - " +
                pageTotals +
                " / " +
                totalResults +
                "</p>";

            const buttons =
                '<button type="button" data-prev-page="' +
                (currentPage - 1) +
                '" class="' +
                prevClass +
                ' ">' +
                leftArrow +
                prevText +
                '</button><button type="button"  data-next-page="' +
                (currentPage + 1) +
                '" class="' +
                nextClass +
                '">' +
                nextText +
                rightArrow +
                "</button>";

            const components = pageCounter + buttons;

            this.navigationCont.html(components);
            this.enableBtns(currentPage, totalPages);
        },

        /** results template */
        resultsTemplate: function (resultsData) {
            const cardGroup = [];
            $.map(
                resultsData,
                function (result, i) {
                    const description = result.description;
                    const thumbnailUrl = result.thumbnailUrl;
                    const title = result.title;
                    const type = result.type;
                    const url = result.url;
                    const vendor = result.vendor;
                    const role = this.resultsCont.data().role; //heading
                    const imgClass = this.resultsCont.data().imgClass; //image
                    const imgLoading = this.resultsCont.data().imgLoading; //lazy
                    const contentClass = this.resultsCont.data().contentClass; //content
                    const typeClass = this.resultsCont.data().typeClass; //type
                    const ctaClass = this.resultsCont.data().ctaClass; //cta
                    const ctaText = this.resultsCont.data().ctaText; //Download

                    const showVendor =
                        vendor === "(No vendor)" ? "no-display-label" : "";

                    const card =
                        '<div class="card-container"><a role="' +
                        role +
                        '" aria-level="' +
                        (i + 1) +
                        '" href="' +
                        url +
                        '" target="_blank"><div class="' +
                        imgClass +
                        '" style="background-image: url(' +
                        thumbnailUrl +
                        ');"><img src="' +
                        thumbnailUrl +
                        '" loading="' +
                        imgLoading +
                        '" width="200" height="100" ></div><div class="' +
                        contentClass +
                        '"><div class="' +
                        typeClass +
                        '"> ' +
                        type +
                        '</div><div class="tags ' +
                        showVendor +
                        '" >' +
                        vendor +
                        "</div><h3>" +
                        title +
                        "</h3><p>" +
                        description +
                        '</p></div><div class="' +
                        ctaClass +
                        '"><span>' +
                        ctaText +
                        "</span></div></a></div> ";

                    cardGroup.push(card);
                }.bind(this)
            );
            this.resultsCont.html(cardGroup);
        },

        /** adding results to each array form the api call */
        resultsIteration: function (dataResults, resultsArray) {
            for (var i = 0; i < dataResults.length; i++) {
                resultsArray.push(dataResults[i]);
            }
        },

        /** handling no results message */
        enableNoResultsMessage: function () {
            this.noResultsCont.removeClass("hidden");
        },

        /** handling no results message */
        disableNoResultsMessage: function () {
            this.noResultsCont.addClass("hidden");
        },

        /** hidding no results count item li */
        handleNoResultsFilter: function () {
            $.map($(".item-total-count"), function (item) {
                if (item.dataset.totalCount === "0") {
                    item.parentElement.classList.add("hide");
                }
            });
        },

        /** Display limit Elements in Vendors Filters*/
        displayFilterElementVendors: function () {
            var activeVendors = $(".vendors-filter .filter-options:not(.hide)");
            const showMore = "show-more";
            const showLess = "show-less";

            this.addClassToLimitVendors(
                activeVendors,
                0,
                this.vendorsLimitFilter,
                showMore
            );
            this.addClassToLimitVendors(
                activeVendors,
                this.vendorsLimitFilter,
                activeVendors.length,
                showLess
            );
            $(this.seeMoreVendors).show();

            if (activeVendors.length < this.vendorsLimitFilter) {
                $(this.seeMoreVendors).hide();
            }
        },

        /** update results APi load */
        updateResults: function (url, data) {
            this.initialResults = false;
            $(".loading").show();
            $.post(
                url,
                data,
                function (results) {
                    if (results) {
                        this.paginationTemplate(results.pagination);
                        this.resultsTemplate(results.results);
                        var filterCount = $(".item-count");
                        $.map(filterCount, function (category) {
                            category.nextElementSibling.classList.remove(
                                "display"
                            );

                            $.map(
                                results.technologyFacets,
                                function (techFacets) {
                                    if (
                                        techFacets.name ===
                                        category.dataset.itemName
                                    ) {
                                        category.innerText = techFacets.count;

                                        if (
                                            techFacets.count !==
                                            Number(
                                                category.nextElementSibling
                                                    .dataset.totalCount
                                            )
                                        ) {
                                            category.nextElementSibling.classList.add(
                                                "display"
                                            );
                                        }
                                    }
                                }
                            );

                            $.map(
                                results.vendorFacets,
                                function (vendorFacets) {
                                    if (
                                        vendorFacets.name ===
                                        category.dataset.itemName
                                    ) {
                                        category.innerText = vendorFacets.count;

                                        if (
                                            vendorFacets.count !==
                                            Number(
                                                category.nextElementSibling
                                                    .dataset.totalCount
                                            )
                                        ) {
                                            category.nextElementSibling.classList.add(
                                                "display"
                                            );
                                        }
                                    }
                                }
                            );

                            $.map(results.typeFacets, function (typeFacets) {
                                if (
                                    typeFacets.name ===
                                    category.dataset.itemName
                                ) {
                                    category.innerText = typeFacets.count;

                                    if (
                                        typeFacets.count !==
                                        Number(
                                            category.nextElementSibling.dataset
                                                .totalCount
                                        )
                                    ) {
                                        category.nextElementSibling.classList.add(
                                            "display"
                                        );
                                    }
                                }
                            });
                        });

                        if (results.pagination.totalPages === 0) {
                            this.enableNoResultsMessage();
                        } else {
                            this.disableNoResultsMessage();
                        }

                        this.displayFilterElementVendors();
                    }
                }.bind(this)
            ).done(function () {
                $(".loading").hide();
            });
        },

        /** selecting the template view*/
        activateTemplateView: function () {
            if (this.listViewType === "image-view") {
                this.viewImgBtn.addClass("active");
                this.resultsCont.addClass("group5");
            } else {
                this.viewListBtn.addClass("active");
                this.resultsCont.addClass("group3");
            }
        },

        /** selecting the template view*/
        imgView: function () {
            this.viewImgBtn.addClass("active");
            this.viewListBtn.removeClass("active");
            this.resultsCont.addClass("group5");
            this.resultsCont.removeClass("group3");
        },

        /** selecting the template view*/
        listView: function () {
            this.viewListBtn.addClass("active");
            this.viewImgBtn.removeClass("active");
            this.resultsCont.addClass("group3");
            this.resultsCont.removeClass("group5");
        },

        /** adding listeners to btn views*/
        btnListeners: function () {
            $(this.viewImgBtn).on("click", this.imgView.bind(this));
            $(this.viewListBtn).on("click", this.listView.bind(this));
        },

        /** add class to filter vendors elements*/
        addClassToLimitVendors: function (
            element,
            initialValue,
            limitValue,
            className
        ) {
            for (var i = initialValue; i < limitValue; i++) {
                $(element[i]).addClass(className);
            }
        },

        /** Api load */
        loadData: function (url, data) {
            $(".loading").show();
            $.post(
                url,
                data,
                function (results) {
                    if (results) {
                        this.activateTemplateView();
                        this.totalPages = results.pagination.totalPages;
                        this.paginationTemplate(results.pagination);
                        this.resultsTemplate(results.results);
                        this.resultsIteration(
                            results.technologyFacets,
                            this.technologyResults
                        );
                        this.resultsIteration(
                            results.typeFacets,
                            this.typeResults
                        );
                        this.resultsIteration(
                            results.vendorFacets,
                            this.vendorsResults
                        );

                        if (results.pagination.totalPages === 0) {
                            this.enableNoResultsMessage();
                        } else {
                            this.disableNoResultsMessage();
                        }
                    }
                }.bind(this)
            ).done(
                function () {
                    const categories = {
                        technology: "technology",
                        type: "type",
                        vendor: "vendor",
                    };

                    this.filterTemplate(
                        this.technologyResults,
                        this.techFilterCont,
                        categories.technology
                    );
                    this.filterTemplate(
                        this.typeResults,
                        this.typesFilterCont,
                        categories.type
                    );
                    this.filterTemplate(
                        this.vendorsResults,
                        this.vendorsFilterCont,
                        categories.vendor
                    );
                    this.addEventsListeners();
                    this.btnListeners();
                    this.handleNoResultsFilter();
                    this.displayFilterElementVendors();
                    $(".loading").hide();
                }.bind(this)
            );
        },
    });

    if ($(".resource-library").length > 0) {
        var resourceLibrary = new westcon.views.resourceLibrary();
        resourceLibrary.begin();
    }
})(westcon);
