//This component has been repurposed into an events module

if (document.querySelector(".component-event-module #app")) {
    initializeEventModule();
}

//eslint needs updating to permit async functions >:-(
// async function fetchEventModuleDetails() {
//     try {
//         //fetch the details about which files to include from a manually updated JSON file
//         const response = await fetch("/content/dam/apps/events/event-app.json");
//         // expect format { "script": "c316b4d5", "css": "434cd248" }
//         if (!response.ok) {
//             throw new Error(`HTTP error: ${response.status}`);
//         }
//         const data = await response.json();
//         return data;
//     } catch (error) {
//         console.error(error);
//     }
// }

function initializeEventModule() {
    const eventContainer = document.querySelector(".component-event-module #app");

    //exit if in editing mode (data-edit-mode exists on the body)
    //don't want to activate the events module while editing
    if (Object.prototype.hasOwnProperty.call(document.body.dataset, "editMode")) {
        return;
    }

    //the events module should only be place on a page ONCE.
    //detect if the script has already been added and warn the user if this is the PREVIEW mode
    if (document.querySelector("script[src*=apps][src*=events]") instanceof HTMLScriptElement) {
        if (window.location.search.startsWith("?wcmmode=disabled")) {
            const warnUser = eventContainer.querySelector("#app");
            if (warnUser) {
                warnUser.innerText = "You can only place the events module once on a single page";
            }
        }
        return;
    }

    try {
        //fetch the details about which files to include from a manually updated JSON file
        const fetchLogInStatus = fetch("/content/dam/apps/events/event-app.json");
        // expect format { "script": "c316b4d5", "css": "434cd248" }

        fetchLogInStatus
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                if (data.script && data.css) {
                    //maybe implement check to see if script already added by a different module
                    //document.querySelector('script[src*=apps][src*=events]')

                    const script = document.createElement("script");
                    script.async = true;
                    script.type = "module";
                    script.src = `/content/dam/apps/events/assets/index.${data.script}.js`;

                    const css = document.createElement("link");
                    css.rel = "stylesheet";
                    css.href = `/content/dam/apps/events/assets/index.${data.css}.css`;

                    document.body.append(script, css);
                }
            })
            .catch((error) => {
                console.error(`Error during fetch of log in status: ${error}`);
            });
    } catch (error) {}
}

(function (westcon) {
    "use strict";

    westcon.views.eventModule = Backbone.View.extend({
        initialize: function () {},
    });
})(westcon);
