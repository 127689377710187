//only run on the pages with the search-ux module (i.e. vendors and blog search page)

if (document.querySelector(".search-ux")) {
    const url = new URL(window.location.href);
    const scrollTo = document.querySelector("[data-scroll-to-here]");
    const dropdownContainers = document.querySelectorAll(".search-ux-dropdown");

    if (scrollTo) {
        window.scrollTo({
            top: scrollTo.offsetTop,
            behavior: "smooth",
        });
    }

    //function for checking if the click occurred from a descent of another collection of parents
    function isDescendant(parents, target) {
        for (let i = 0; i < parents.length; i++) {
            if (parents[i].contains(target)) {
                return true;
            }
        }
        return false;
    }

    function closeAllDropdowns() {
        document.querySelectorAll(".search-ux-dropdown [aria-expanded]").forEach((t) => t.setAttribute("aria-expanded", false));
    }

    window.addEventListener("click", (event) => {
        const target = event.target;

        //if the click occurred from outside any of the dropdownContainers, then exit close dropdowns
        if (!isDescendant(dropdownContainers, target)) {
            closeAllDropdowns();
            return;
        }

        //was the click on one of the dropdownToggle?
        //(the elements inside the button are set to pointer-events:none)
        if (target.matches(".search-ux-dropdown button")) {
            event.stopPropagation();
            //store the initial state and then close all open dropdowns
            const initial = target.getAttribute("aria-expanded") === "true";
            closeAllDropdowns();
            target.setAttribute("aria-expanded", !initial);
        }

        //was the click on one of the specific filters in the menu
        if (target.matches(".search-ux-filter")) {
            event.preventDefault();
            performFilterRefresh(target.closest(".search-ux-dropdown").dataset.filter, target.dataset.value);
        }
    });

    //* <button> controls the opening and closing of the menus,
    //* indicated with [aria-expanded] as the state

    const searchInput = document.querySelector(".search-ux-search input");
    const searchButton = document.querySelector("search-ux-button");

    if (searchInput) {
        searchInput.addEventListener("keypress", function (event) {
            if (event.which == 13) {
                performFilterRefresh(searchInput.dataset.filter, searchInput.value);
            }
        });
    }

    if (searchButton) {
        searchButton.addEventListener("click", () => {
            performFilterRefresh(searchInput.dataset.filter, searchInput.value);
        });
    }

    const clearButton = document.querySelector(".search-ux-clear");

    if (clearButton) {
        clearButton.addEventListener("click", () => {
            window.location.href = url.origin + url.pathname;
        });
    }

    const performFilterRefresh = (name, value) => {
        //in case the user has already been searching and reached multiple pages before
        //applying a new filter, get rid of the page search parameter
        url.searchParams.delete("page");

        if (!value) {
            //if the value is empty, it indicates the parameter should be removed
            url.searchParams.delete(name);
        } else {
            //update the parameter
            url.searchParams.set(name, value);
        }
        window.location.href = url.href;
    };
}

// function getAllUrlParams() {
//     const queryString = window.location.search;
//     const urlParams = new URLSearchParams(queryString);
//     const obj = {};

//     for (const [key, value] of urlParams.entries()) {
//         const matches = key.match(/^(.+)\[(\d*)\]$/);
//         if (matches) {
//             const name = matches[1];
//             const index = matches[2] || 0;
//             if (!obj[name]) {
//                 obj[name] = [];
//             }
//             obj[name][index] = value;
//         } else {
//             obj[key] = value;
//         }
//     }

//     return obj;
// }

//–––––––––––––––––––––––––––––––––
//–––––––––––––––––––––––––––––––––
//–––––––––––––––––––––––––––––––––
// $(document).ready(function () {
//     var scrollTo = $(".vendors-list__scroll-to");
//     var dropdowns = $(".vendors-list__dropdown");
//     var url = location.protocol + "//" + location.host + location.pathname;
//     var params = getAllUrlParams();

//     if (scrollTo.length) {
//         $("html, body").animate({
//             scrollTop: scrollTo.offset().top,
//         });
//     }

//     if (dropdowns.length) {
//         $(window).click(function () {
//             $(".vendors-list__dropdown-toggle").removeClass("vendors-list__dropdown-toggle--open");
//             $(".vendors-list__dropdown-list").slideUp();
//         });
//     }

//     dropdowns.each(function () {
//         $(this).click(function (event) {
//             event.stopPropagation();
//         });

//         var toggle = $(this).find(".vendors-list__dropdown-toggle"),
//             list = $(this).find(".vendors-list__dropdown-list");

//         toggle.on("click", function () {
//             $(".vendors-list__dropdown-list").not(list).slideUp();

//             if (toggle.hasClass("vendors-list__dropdown-toggle--open")) {
//                 toggle.removeClass("vendors-list__dropdown-toggle--open");
//                 list.slideUp();
//             } else {
//                 toggle.addClass("vendors-list__dropdown-toggle--open");
//                 list.slideDown();
//             }
//         });
//     });

//     $(".vendors-list__filters-mobile-toggle").on("click", function () {
//         if ($(this).hasClass("vendors-list__filters-mobile-toggle--open")) {
//             $(this).removeClass("vendors-list__filters-mobile-toggle--open");
//             $(".vendors-list__filters").slideUp();
//         } else {
//             $(this).addClass("vendors-list__filters-mobile-toggle--open");
//             $(".vendors-list__filters").slideDown();
//         }
//     });

//     $(".vendors-list__search-input").on("keypress", function (e) {
//         if (e.which == 13) {
//             params = {};
//             filter($(".vendors-list__search").data("filter"), $(this).val());
//         }
//     });

//     $(".vendors-list__search-button").on("click", function (e) {
//         params = {};
//         filter($(".vendors-list__search").data("filter"), $(".vendors-list__search-input").val());
//     });

//     $(".vendors-list__dropdown-option").on("click", function (e) {
//         e.preventDefault();
//         filter($(this).parents(".vendors-list__dropdown").data("filter"), $(this).data("value"));
//     });

//     $(".vendors-list__filters-clear").on("click", function (e) {
//         window.location.href = url;
//     });

//     function filter(name, value) {
//         params[name] = value;
//         window.location.href = url + "?" + encodeParams();
//     }

//     function encodeParams() {
//         var url_params = "";
//         for (var key in params) {
//             if (url_params != "") {
//                 url_params += "&";
//             }
//             url_params += key + "=" + encodeURIComponent(params[key]);
//         }
//         return url_params;
//     }
// });

// function getAllUrlParams() {
//     var queryString = window.location.href.split("?")[1];

//     var obj = {};

//     if (queryString) {
//         queryString = queryString.split("#")[0];

//         var arr = queryString.split("&");

//         for (var i = 0; i < arr.length; i++) {
//             var a = arr[i].split("=");
//             var paramName = a[0];
//             var paramValue = typeof a[1] === "undefined" ? true : a[1];

//             if (paramName.match(/\[(\d+)?\]$/)) {
//                 var key = paramName.replace(/\[(\d+)?\]/, "");
//                 if (!obj[key]) obj[key] = [];

//                 if (paramName.match(/\[\d+\]$/)) {
//                     var index = /\[(\d+)\]/.exec(paramName)[1];
//                     obj[key][index] = paramValue;
//                 } else {
//                     obj[key].push(paramValue);
//                 }
//             } else {
//                 if (!obj[paramName]) {
//                     obj[paramName] = paramValue;
//                 } else if (obj[paramName] && typeof obj[paramName] === "string") {
//                     obj[paramName] = [obj[paramName]];
//                     obj[paramName].push(paramValue);
//                 } else {
//                     obj[paramName].push(paramValue);
//                 }
//             }
//         }
//     }

//     return obj;
// }
