(function (westcon) {
    'use strict';

    westcon.views.practiceNav = Backbone.View.extend({

        events: {
            'click .practice-nav-list-item-link': 'select',
            'click .action-dropdown': 'toggleMenu'
        },

        /**
         * Initialize
         */
        initialize: function (e) {
            this.$parentEl = e.parentEl;
            this.$arrows = this.$('.practice-nav-list-item-arrow');
            this.$listItems = this.$('.practice-nav-list-item');
            this.$window = $(window);
            this.$dropdown = this.$('.action-dropdown');
            this.setSelected();
            this.scroll();
        },

        /**
         * Gets url fragment
         *
         */
        fragment: function () {
            return Backbone.history.fragment;
        },

        /**
         * Scroll to the top of the page if
         * not current tab
         */
        scrollToTop: function (route) {
            if (this.fragment() !== route) {
                globals.scrollToTop();
            }
        },

        /**
         * Sets the selected tab from url fragment
         */
        setSelected: function () {
            var tab = this.$el.find('[href="#' + this.fragment() + '"]');
            this.$listItems.removeClass('selected');
            tab.parent('.practice-nav-list-item').addClass('selected');
        },

        /**
         * Show or hide practice bar
         */
        toggleMenu: function () {
            if (this.getCurrentMedia() !== 'desktop') {
                if (!this.isOpen()) {
                    this.showListItems();
                } else {
                    this.hideListItems();
                }
            }
        },

        /**
         * Is the menu open
         */
        isOpen: function () {
            return this.$listItems.hasClass('show');
        },

        /**
         * Show unselected list items
         */
        showListItems: function () {
            var listItems = this.$('.practice-nav-list-item:not(".selected")');
            listItems.addClass('show');
            this.$dropdown.addClass('open');
        },

        /**
         * Hide all list items include selected items
         */
        hideListItems: function () {
            this.$listItems.removeClass('show');
            this.$dropdown.removeClass('open');
        },

        /**
         * Get parents bottom location
         */
        parentBottomLoc: function () {
            return this.$parentEl.offset().top + this.$parentEl.outerHeight(true);
        },

        /**
         * Get bottom of view port location
         */
        viewPortBottom: function () {
            return this.$window.scrollTop() + this.$window.height();
        },

        /**
         * Is the view port bottom position equal to or grater than
         * the parents bottom position.
         */
        isAtBottom: function () {
            return this.parentBottomLoc() >= this.viewPortBottom();
        },

        /**
         * Fix to screen or to bottom of parent
         *
         */
        toggleFixed: function () {
            if (this.isAtBottom()) {
                this.$el.addClass('fixed');
            } else {
                this.$el.removeClass('fixed');
            }
        },

        /**
         * Select a new list item
         */
        select: function (e) {
            this.$listItems.removeClass('selected');
            $(e.currentTarget)
                .parents('.practice-nav-list-item')
                .addClass('selected');
            this.scrollToTop();
            this.toggleMenu();
        },

        /**
         * Keep the right side sticky
         */
        scroll: function () {
            $(window).bind('scroll.stickyNav', function () {
                this.toggleFixed();
            }.bind(this));
        }
    });
}(westcon));
