(function (westcon) {
    'use strict';

    westcon.views.ad = Backbone.View.extend({

        /**
         * initialize
        */
        initialize: function () {
            this.$adImg = $('.ad-link img');
            this.desktopImgSrc = this.$adImg.data('desktop-src');
            this.mobileImgSrc = this.$adImg.data('mobile-src');

            this.renderAdImage();

            this.listenToMedia(this.renderAdImage.bind(this));
        },

        /**
         * Render desktop Ad Image
         */
        renderAdImage: function () {
            if (this.getCurrentMedia() === 'desktop') {
                this.$adImg.attr('src', this.desktopImgSrc);
            } else {
                this.$adImg.attr('src', this.mobileImgSrc);
            }
        }

    });
}(westcon));
