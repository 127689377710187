(function (westcon) {
    "use strict";

    westcon.views.countrySelector = Backbone.View.extend({
        events: {
            click: "stopPropagation",
            "click .language-picker .label": "toggle",
            "change select[name=countries]": "choose",
            "change select[name=lang]": "choose",
            "change .remember": "toggleNote",
            "click button:not(:disabled)": "select",
        },

        /**
         * Remember me cookie name
         */
        cookie: "myhome",

        /**
         * Is Fetched?
         */
        isFetched: false,

        /**
         * Initialize
         */
        initialize: function () {
            this.template = _.template($("#countrySelector").html());
            this.language = _.template($("#selectLanguage").html());
            // this.close = _.template($("#close").html());

            this.$languagePicker = this.$(".language-picker");
            this.$overlay = this.$(".language-overlay");

            this.model = new westcon.models.countrySelector({
                url: this.$el.data("countries-url"),
            });

            // Close Overlay on clicking outside the overlay
            $("body").on("click", () => {
                this.toggle.bind(this, false);
            });

            // render once models is changed
            this.listenTo(this.model, "change", this.render.bind(this));
        },

        render: function () {
            this.model.postFetch();
            // this.$overlay.html("");

            this.$overlay.html(
                this.template(
                    _.extend(this.model.serializeModel(), {
                        i18n: this.model.getI18n(),
                    })
                )
            );
            this.$language = this.$(".language-content");
            this.$remember = this.$(".remember");
            this.$note = this.$(".note");
            this.$country = this.$("select[name=countries]");
            this.$select = this.$("button");
            this.renderLanguages();
            this.toggleNote();
            this.toggleRemember();
            this.toggleSelect();

            // $(".close").on("click", function () {
            //     $(".language-picker").removeClass("open");
            // });

            this.$("button:not([disabled])").click(function () {
                $(".language-picker").removeClass("open");
            });
        },

        /**
         * Render Languages
         * @param {string} country - optional
         */
        renderLanguages: function (country) {
            this.$language.html(
                this.language({
                    languages: this.model.getLanguages(country),
                })
            );

            this.$lang = this.$("select[name=lang]");
        },

        stopPropagation: function (e) {
            e.stopPropagation();
        },

        choose: function () {
            this.model.setAll({
                country: this.$country.val(),
                language: this.$lang.val(),
            });
        },

        /**
         * Toggle Overlay
         * @param {boolean} toggle
         */
        toggle: function (toggle) {
            //close potentially open popups for searchbox and partnercentral
            globals.closePopup.partnercentral();
            globals.closePopup.search();

            this.$languagePicker.toggleClass("open", toggle);

            if (!this.isFetched) {
                this.model.fetch();
                this.isFetched = true;
            }
        },

        toggleNote: function () {
            //moved to control via CSS
            // this.$note.toggle(this.$remember.is(":checked"));
        },

        toggleRemember: function () {
            this.$remember.parent().toggle(!!this.model.getLanguageUrl(this.$country.val(), this.$lang.val()));
        },

        toggleSelect: function () {
            this.$select.prop(
                "disabled",
                !this.model.isCountryChanged(this.$country.val()) && !this.model.isLanguageChanges(this.$lang.val())
            );
        },

        toggleCookie: function () {
            if (this.$remember.is(":checked")) {
                if (typeof ContextHub !== "undefined") {
                    ContextHub.Utils.Cookie.setItem(
                        this.cookie,
                        this.model.getLanguageUrl(this.$country.val(), this.$lang.val()),
                        { expires: 365 }
                    );
                }
            } else {
                if (typeof ContextHub !== "undefined") {
                    ContextHub.Utils.Cookie.removeItem(this.cookie);
                }
            }
        },

        select: function () {
            this.toggleCookie();
            var url = this.model.getLanguageUrl(this.$country.val(), this.$lang.val());

            if (url.indexOf("http") > -1) {
                window.open(url, "_blank");
            } else {
                window.location.href = url;
            }
        },
    });
})(westcon);
